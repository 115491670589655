import Logo from '../Logo'

import React, {useState, useContext, useEffect, useRef} from 'react'
import { Dialog } from '@headlessui/react'
import {Bars3Icon, XMarkIcon, UserCircleIcon, ExclamationCircleIcon, OpenLinkInNewTabIcon} from '../icons/Icons'
import {Link} from "react-router-dom";
import {UserContext} from '../UserProvider';
import { useLocation } from 'react-router-dom';
import {EstatePlanSummaryContext} from "../EstatePlanSummaryProvider";
import useSortPackageByOwner from "../../client/useSortPackageByOwner";
import useGetAttorneyStates from "../../client/CustomHooks/useGetAttorneyStates";
import Button, {ButtonSize} from '../buttons/Button';
import {useDetermineAccess} from "../../client/CustomHooks/useDetermineAccess";
import {LegalFileContext} from "../LegalFileProvider";
import {NotifyPackageNotSignedContext} from "../../client/NotifyPackageNotSignedProvider";
import EG from "../../../helpers/EG";
import useSetHeaderHeight from "../../client/CustomHooks/useSetHeaderHeight";
import Definitions from '../../../definitions';
import Security from "../../../security";
import Tooltip from "../Tooltip";
import {useSandbox} from "../UtilsProvider";
import SwitchButton from "../buttons/SwitchButton";
import {WhiteLabelAdvisorSelector, WhiteLabelAdvisorSelectorProps} from "../../partner/views/WhiteLabelAdvisorSelector";
import SandboxBanner from "../SandboxBanner";


const clientNavigation: iClientNavigationItem[] = [
    { name: 'Dashboard', href: '', completeTriageFirst: false },
    { name: 'Plan Participants', href: 'participants', completeTriageFirst: true },
    { name: 'Assets', href:  'assets', completeTriageFirst: true },
    { name: 'Deeds', href:  'deeds', completeTriageFirst: true },
]

const partnerNavigation = [
    { name: 'Clients', href: '', completeTriageFirst: false },
    { name: 'Shipping', href: 'shipping', completeTriageFirst: false },
    { name: 'Attorneys', href: 'attorney', completeTriageFirst: false },
    { name: 'White Labels', href: 'whitelabels', completeTriageFirst: false },
    { name: 'Reports', href: 'reports', completeTriageFirst: false },
    { name: 'Resources', href:  'https://estateguruhelp.zendesk.com/hc/en-us/categories/13856081597709-Advisors', completeTriageFirst: false, hasIcon: <OpenLinkInNewTabIcon className={"ml-1 w-4"}/> },
]

interface iClientNavigationItem {
    name: string;
    href: string;
    completeTriageFirst: boolean;
}

export const showNotificationIcon = (legalFile: any, clientNavigationItem: iClientNavigationItem | undefined = undefined) => {
    if (!legalFile) return false;
    if (clientNavigationItem === undefined || clientNavigationItem.name === 'Deeds') {
        const deeds = legalFile.getLegalFile("common")?.get(Definitions.legalFileFields.deed.name) ?? []; // NOTE: Deeds are only stored in the common legal file model
        if (deeds.length > 0) {
            // Look for an incomplete deed
            const unfinishedDeed = deeds.find((deed: any) => deed.status === "initial" && !deed.isReadyForPurchase);
            if (unfinishedDeed) {
                return true;
            }
        }
    }
    else if (clientNavigationItem.name === 'Assets') {
        const commonAssets = legalFile.getLegalFile("common")?.get(Definitions.legalFileFields.potentialAssets.name) ?? [];
        const primaryAssets = legalFile.getLegalFile("primary")?.get(Definitions.legalFileFields.potentialAssets.name) ?? [];
        const secondaryAssets = legalFile.getLegalFile("secondary")?.get(Definitions.legalFileFields.potentialAssets.name) ?? [];
        let assets = [].concat(commonAssets, primaryAssets, secondaryAssets);
        if (assets.length > 0)
        {
            // Look for an incomplete asset
            const unfinishedAsset = assets.find((asset: any) => !asset.isGenerated);
            if (unfinishedAsset) {
                return true;
            }
        }
    }
    return false;
}

export default function Header() {
    const location = useLocation();
    const [estatePlanSummary, setEstatePlanSummary] = useContext(EstatePlanSummaryContext);
    const [legalFile] = useContext(LegalFileContext);
    const [, setLegalFile] = useContext(LegalFileContext);
    const [user, setUser] = useContext(UserContext);
    const [, setShowNotifyPackageNotSigned] = useContext(NotifyPackageNotSignedContext);
    const pathSegments = location.pathname.split('/').filter(Boolean);
    const lastSegment = pathSegments[pathSegments.length - 1];
    const packages: any = useSortPackageByOwner();
    const attorneyStates: string[] | null = useGetAttorneyStates();
    const [navigationMap] = useState<any[]>(clientNavigation);
    const headerRef = useRef<HTMLDivElement | null>(null);

    const getTabNameFromUrlPath = () => {
        const matchingTab = navigationMap.find(tab => tab.href === lastSegment);
        return matchingTab ? matchingTab.name : 'Dashboard';
    };

    const [selectedTab, setSelectedTab] = useState(getTabNameFromUrlPath());
    const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
    const [triageComplete, setTriageComplete] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState(true);
    const [stateAvailable, setStateAvailable] = useState<boolean>(false);
    const { isStakeholder, hasAnyAssetsAccess } = useDetermineAccess();

    useSetHeaderHeight(selectedTab, headerRef);

    useEffect(() => {
        setSelectedTab(getTabNameFromUrlPath());
    }, [lastSegment]);

    useEffect(() => {
        if (!packages || !estatePlanSummary || !attorneyStates) {
            setIsLoading(true)
        } else {
            if (estatePlanSummary.triage?.firstName !== undefined) {
                setTriageComplete(true);
            }
            if (attorneyStates.includes(estatePlanSummary.triage?.state) || estatePlanSummary.triage?.firstName === undefined) {
                setStateAvailable(true);
            }
            setIsLoading(false);
        }
    }, [packages, estatePlanSummary, attorneyStates]);

    const hasWillAlias = (packages: any[]) => {
        if (!packages?.length) return;
        return packages.some((pkg: any) => pkg.alias === "will");
    }

    const showLink = (item: any) => {
        const hideAssetsAndDeedsOnly = (link: any) => {
            return !['Dashboard', 'Plan Participants'].includes(link.name)
        }

        const hideDeedsOnly = (link: any) => {
            return !['Dashboard', 'Plan Participants', 'Assets'].includes(link.name);
        }

        let show = true;
        if (!stateAvailable) { // hide all links if state isn't available
            show = false;
        }
        if (!triageComplete && item.completeTriageFirst) { // hide all links if triage isn't complete
            show = false;
        }
        if (hasWillAlias(packages?.primary) && hideAssetsAndDeedsOnly(item)) { // hide "Assets" and "Deeds" if it's a Will package
            show = false;
        }
        if (packages.joint?.[0]?.alias === "none" && hideAssetsAndDeedsOnly(item)) { // hide "Assets" and "Deeds" if joint package alias === "none"
            show = false;
        }
        if (isStakeholder && hideAssetsAndDeedsOnly(item)) {
            show = false;
        }
        if (isStakeholder && hideDeedsOnly(item)) {
            show = false;
        }
        if (isStakeholder && item.name === "Plan Participants") { // hide "Plan Participants" if the user is a stakeholder
            show = false;
        }

        return show;
    }

    const handleCloseTab = () => {
        window.close();
    }

    const shouldShowCloseWindowButton = (): boolean => {
        let shouldShow = false;
        if (user?.systemPrivs) {
            const roleStrings = [Definitions.roles.sysAdmin.name, Definitions.roles.admin.name, Definitions.roles.advisor.name];
            const containsRole = user?.systemPrivs?.roles?.some((item: string) => roleStrings.includes(item));
            if (containsRole) {
                shouldShow = true;
            }
        }
        return shouldShow;
    }

    return (
        <>
            {
                isLoading ||
                    <div ref={headerRef} className={"bg-headerColor shadow-md"}>
                        <header className={'w-full pr-[32px] pl-[24px] py-2'}>
                            <nav className="flex items-stretch justify-between lg:px-8 min-h-[50px] max-h-headerMaxHeight" aria-label="Global">
                                <Link to={''} className="py-6 text-secondary">
                                    <Logo type={'noName'} additionalClasses={'w-12'}/>
                                </Link>
                                <div className="flex md:hidden std:hidden lg:hidden xl:hidden">
                                    <button
                                        type="button"
                                        className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700"
                                        onClick={() => setMobileMenuOpen(true)}
                                    >
                                        <span className="sr-only">Open main menu</span>
                                        <Bars3Icon className="w-6 text-headerElementsColor" aria-hidden="true"/>
                                    </button>
                                </div>
                                <div className="hidden md:flex std:flex lg:flex xl:flex gap-x-[98px] items-center">
                                    <div className={'flex gap-x-[24px] items-stretch'}>
                                        {navigationMap.map((item) => (
                                            showLink(item) ?
                                                showNotificationIcon(legalFile, item) ?
                                                    <Tooltip message={"Some items require your attention here"}>
                                                        <div className={"flex flex-col group"}>
                                                            <Link
                                                                key={item.name}
                                                                to={item.href + ''}
                                                                className={`text-sm font-semibold leading-6 text-headerElementsColor flex px-2 whitespace-nowrap items-center h-full`}
                                                            >
                                                                <div className={"flex items-center"}>
                                                                    {item.name}
                                                                    {/*If they have unfinished deeds or assets show a notification icon*/}
                                                                    <span className={"text-error ml-1"}><ExclamationCircleIcon
                                                                        className={"w-4"}/></span>
                                                                </div>
                                                            </Link>
                                                            <div className={`${selectedTab === item.name ? "border-secondary" : "border-transparent"} group-hover:border-secondary border-b-[6px]`}></div>
                                                        </div>
                                                    </Tooltip> :
                                                    <div className={"flex flex-col group"}>
                                                        <Link
                                                            key={item.name}
                                                            to={item.href + ''}
                                                            className={`text-sm font-semibold leading-6 text-headerElementsColor flex px-2 whitespace-nowrap items-center h-full`}
                                                        >
                                                            {item.name}
                                                        </Link>
                                                        <div className={`${selectedTab === item.name ? "border-secondary" : "border-transparent"} group-hover:border-secondary border-b-[6px]`}></div>
                                                    </div>
                                            : <></>
                                            ))}
                                    </div>
                                    <div className={'flex border-l pl-5 gap-x-4 py-2 justify-end items-center '}>
                                        <div className={'flex gap-x-4'}>
                                            {(user.user.userData?.enableBetaEnvironment === '1' || window.SERVER_DATA.whiteLabel.settings.enableBetaEnvironment === '1') && (
                                                <div className={'flex items-center'}>
                                                    <Button
                                                        className={'!border-headerElementsColor !text-headerElementsColor !bg-transparent'}
                                                        id={'goToClassicButtonClient'}
                                                        buttonSize={ButtonSize.ExtraSmall}
                                                        onClick={() => EG.goToClassicView(user.user, "/#plan")}
                                                    >
                                                        Classic View
                                                    </Button>
                                                </div>
                                            )}
                                            <div className={'flex items-center p-2'}>
                                                <Link to={'/app/client/profile'}>
                                                    <UserCircleIcon
                                                        className={'w-6 hover:bg-headerElementsColor hover:text-headerColor text-headerElementsColor hover:rounded-full'}/>
                                                </Link>
                                            </div>
                                        </div>
                                        {shouldShowCloseWindowButton() ?
                                            <span
                                                onClick={() => handleCloseTab()}
                                                className="text-sm font-semibold leading-6 cursor-pointer text-headerElementsColor px-2"
                                            >
                                                Close
                                            </span>
                                            :
                                            <span
                                                onClick={() => EG.logout([setLegalFile, setEstatePlanSummary, setShowNotifyPackageNotSigned, setUser])}
                                                className="text-sm font-semibold leading-6 cursor-pointer text-headerElementsColor px-2"
                                            >
                                                Logout
                                            </span>
                                        }
                                        {/*TODO: Remove the button below once the redesign is out of Beta*/}
                                    </div>
                                </div>
                            </nav>
                            <Dialog as="div" className="lg:hidden" open={mobileMenuOpen} onClose={setMobileMenuOpen}>
                                <div className="fixed inset-0 z-10"/>
                                <Dialog.Panel
                                    // @ts-ignore
                                    focus="true"
                                    className="fixed inset-y-0 right-0 z-10 w-full overflow-y-auto bg-headerColor px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10"
                                >
                                    <div className="flex items-center justify-between">
                                        <a href="#" className="-m-1.5 p-1.5">
                                            <span className="sr-only">Estate Guru</span>
                                            <Logo type={'noName'} additionalClasses={'w-12 h-[50px]'}/>
                                        </a>
                                        <button
                                            type="button"
                                            className="-m-2.5 rounded-md p-2.5 text-headerElementsColor"
                                            onClick={() => setMobileMenuOpen(false)}
                                        >
                                            <span className="sr-only">Close menu</span>
                                            <XMarkIcon className="w-6" aria-hidden="true"/>
                                        </button>
                                    </div>
                                    <div className="mt-6 flow-root">
                                        <div className="-my-6 divide-y divide-gray-500/10">
                                            <div className="space-y-2 py-6">
                                                {navigationMap.map((item) => (
                                                    showLink(item) &&
                                                        <Link key={item.name} to={item.href + ''}
                                                              className="-mx-3 block rounded-lg py-2 px-3 text-base font-semibold leading-7 text-headerElementsColor">
                                                            {item.name}
                                                        </Link>
                                                ))}
                                            </div>
                                            <div className="py-6">
                                                <a
                                                    href="#"
                                                    className="-mx-3 block rounded-lg py-2.5 px-3 text-base font-semibold leading-7 text-headerElementsColor"
                                                >
                                                    Log in
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </Dialog.Panel>
                            </Dialog>
                        </header>
                        {
                            estatePlanSummary.isSandbox && <SandboxBanner />
                        }
                    </div>
            }

        </>
    )
}


export function PartnerHeader() {
    const location = useLocation();
    const pathSegments = location.pathname.split('/').filter(Boolean);
    const lastSegment = pathSegments[pathSegments.length - 1];
    const [navigationMap] = useState<any[]>(partnerNavigation);

    const getTabNameFromUrlPath = () => {
        const matchingTab = navigationMap.find(tab => tab.href === lastSegment);
        return matchingTab ? matchingTab.name : 'Clients';
    };

    const [selectedTab, setSelectedTab] = useState(getTabNameFromUrlPath());
    const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
    const [user, setUser] = useContext(UserContext);
    const headerRef = useRef<HTMLDivElement | null>(null);
    useSetHeaderHeight(selectedTab, headerRef);
    const { isSandbox, setSandboxMode } = useSandbox();

    useEffect(() => {
        setSelectedTab(getTabNameFromUrlPath());
    }, [lastSegment]);

    return (
        <>
            <div ref={headerRef} className={"shadow-md bg-headerColor"}>
                <header className={'w-full pr-[32px] pl-[24px] py-2'}>
                    <nav className="mx-auto flex items-stretch justify-between lg:px-8 min-h-[50px] max-h-headerMaxHeight" aria-label="Global">
                        <Link to={''} className="py-6 text-secondary">
                            <Logo type={'noName'} additionalClasses={'w-12'}/>
                        </Link>
                        <div className="flex md:hidden std:hidden lg:hidden xl:hidden">
                            <button
                                type="button"
                                className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700"
                                onClick={() => setMobileMenuOpen(true)}
                            >
                                <span className="sr-only">Open main menu</span>
                                <Bars3Icon className="w-6 text-headerElementsColor" aria-hidden="true"/>
                            </button>
                        </div>
                        <div className="hidden md:flex std:flex lg:flex xl:flex align-bottom gap-x-[98px] items-center">
                            <div className={'flex gap-x-[24px] items-stretch'}>
                                {navigationMap.map((item) => {
                                    if ([ // admin only views, these are inaccessible if you're not an admin, so don't display them in header.
                                        'Shipping',
                                        'Attorneys',
                                        'White Labels',
                                        'Reports'
                                    ].includes(item.name) && !Security.isSysAdmin(user.systemPrivs?.roles)) {
                                        return <></>;
                                    } else if (item.name === 'Resources') {
                                        return (
                                            <div className={"flex flex-col group"}>
                                                <span
                                                    className={`text-sm font-semibold leading-6 text-headerElementsColor cursor-pointer flex px-2 whitespace-nowrap items-center h-full`}
                                                    onClick={() => window.open(item.href + '', 'ZENDESK')}
                                                >
                                                    {item.name} {item.hasIcon ? item.hasIcon : <></>}
                                                </span>
                                                <div className={`${selectedTab === item.name ? "border-secondary" : "border-transparent"} group-hover:border-secondary border-b-[6px]`}></div>
                                            </div>
                                        )
                                    } else {
                                        return (
                                            <div className={"flex flex-col group"}>
                                                <Link
                                                    key={item.name}
                                                    to={item.href + ''}
                                                    className={`text-sm font-semibold leading-6 text-headerElementsColor flex px-2 whitespace-nowrap items-center h-full`}
                                                >
                                                    {item.name}
                                                </Link>
                                                <div className={`${selectedTab === item.name ? "border-secondary" : "border-transparent"} group-hover:border-secondary border-b-[6px]`}></div>
                                            </div>
                                        )
                                    }
                                })}
                            </div>
                            <div className={'flex border-l pl-5 items-center py-2 gap-x-4'}>
                                {(user.user.userData?.enableBetaEnvironment === '1' || window.SERVER_DATA.whiteLabel.settings.enableBetaEnvironment === '1') && (
                                    <div className={'flex items-center space-x-4'}>
                                        <Button
                                            className={'!border-headerElementsColor !text-headerElementsColor !bg-transparent'}
                                            id={'goToClassicButtonPartner'}
                                            buttonSize={ButtonSize.ExtraSmall}
                                            onClick={() => EG.goToClassicView(user.user, "/")}
                                        >
                                            Classic View
                                        </Button>
                                        <div className={"flex flex-col items-center"}>
                                            <div className={"flex text-xs"}>Sandbox mode</div>
                                            <SwitchButton
                                                color={"bg-warning"}
                                                id={'goToSandboxMode'}
                                                size={"sm"}
                                                onChange={() => setSandboxMode(!isSandbox)}
                                                checked={isSandbox}
                                                productFruitClass={"pf_sandbox_mode"} // Product Fruit class name
                                            />
                                        </div>
                                    </div>
                                )}
                                <div className={'flex items-center p-2'}>
                                    <Link to={'/app/partner/profile'}>
                                        <UserCircleIcon
                                            className={'w-6 hover:bg-headerElementsColor hover:text-headerColor text-headerElementsColor hover:rounded-full'}
                                        />
                                    </Link>
                                </div>
                                <span onClick={() => EG.logout([setUser])}
                                      className="text-sm font-semibold leading-6 cursor-pointer text-headerElementsColor px-[8px]">
                                    Logout
                                </span>
                            </div>
                        </div>
                    </nav>
                    <Dialog as="div" className="lg:hidden" open={mobileMenuOpen} onClose={setMobileMenuOpen}>
                        <div className="fixed inset-0 z-10"/>
                        <Dialog.Panel
                            // @ts-ignore
                            focus="true"
                            className="fixed inset-y-0 right-0 z-10 w-full overflow-y-auto bg-headerColor px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10"
                        >
                            <div className="flex items-center justify-between">
                                <a href="#" className="-m-1.5 p-1.5">
                                    <span className="sr-only">Estate Guru</span>
                                    <Logo type={'noName'} additionalClasses={'w-12 h-[50px]'}/>
                                </a>
                                <button
                                    type="button"
                                    className="-m-2.5 rounded-md p-2.5 text-headerElementsColor"
                                    onClick={() => setMobileMenuOpen(false)}
                                >
                                    <span className="sr-only">Close menu</span>
                                    <XMarkIcon className="w-6" aria-hidden="true"/>
                                </button>
                            </div>
                            <div className="mt-6 flow-root">
                                <div className="-my-6 divide-y divide-headerElementsColor">
                                    <div className="space-y-2 py-6">
                                        {navigationMap.map((item) => {
                                            if (item.name === 'Shipping' && !Security.isSysAdmin(user.systemPrivs?.roles)) {
                                                return <></>;
                                            } else if (item.name === 'Resources') {
                                                return (
                                                    <a
                                                        className={"-mx-3 block rounded-lg py-2 px-3 text-base font-semibold leading-7 text-headerElementsColor"}
                                                        href={item.href + ''}
                                                        target={'ZENDESK'}
                                                    >
                                                        {item.name}
                                                    </a>
                                                )
                                            } else {
                                                return (
                                                    <Link
                                                        key={item.name}
                                                        to={item.href + ''}
                                                        className={"-mx-3 block rounded-lg py-2 px-3 text-base font-semibold leading-7 text-headerElementsColor"}
                                                    >
                                                        {item.name}
                                                    </Link>
                                                )
                                            }
                                        })}
                                    </div>
                                    <div className="py-6">
                                        <a
                                            href="#"
                                            className="-mx-3 block rounded-lg py-2.5 px-3 text-base font-semibold leading-7 text-headerElementsColor"
                                        >
                                            Log in
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </Dialog.Panel>
                    </Dialog>
                </header>
                <div className={"bg-information-100 items-stretch"}>
                    {/* WhiteLable/Advisor filtering tool gets injected here. It cannot live here because it interacts with a different component, but needs to be displayed here */}
                    <div id={"whiteLabelAdvisorSelectorPortal"} />
                </div>
                {
                    isSandbox && <SandboxBanner/>
                }
            </div>
        </>
    )
}
