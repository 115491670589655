import React, {createContext, useContext, useEffect, useState} from "react";
import {useCustomQueue} from "../client/CustomHooks/useCustomQueue";

interface userProviderProps {
    children: React.ReactNode
}

export const UtilsContext = createContext<any>(null);

type SandboxContextType = {
    isSandbox: boolean;
    setSandboxMode: (mode: boolean) => void;
};

export const useSandbox = (): SandboxContextType => {
    const context = useContext(UtilsContext);
    if (!context) {
        throw new Error("useSandbox must be used within a UtilsProvider");
    }
    return context;
};

export const UtilsProvider: React.FC<userProviderProps> = ({children }) => {
    const apiPostQueue = useCustomQueue();
    const [isSandbox, setIsSandbox] = useState(false);

    useEffect(() => {
        const storedSandboxMode = localStorage.getItem("isSandbox");
        setIsSandbox(storedSandboxMode === "true"); // Convert string to boolean
    }, []);

    const setSandboxMode = (mode: boolean) => {
        setIsSandbox(mode);
        localStorage.setItem("isSandbox", JSON.stringify(mode)); // Persist state in localStorage
    };

    return (
        <UtilsContext.Provider value={{apiPostQueue: apiPostQueue, isSandbox, setSandboxMode }}>
            {children}
        </UtilsContext.Provider>
    );
};
