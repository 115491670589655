import {useEffect, MutableRefObject, useContext} from "react";
import useObserveSize from "./useObserveSize";
import {useSandbox} from "../../global/UtilsProvider";
import {useDetermineAccess} from "./useDetermineAccess";
import {EstatePlanSummaryContext} from "../../global/EstatePlanSummaryProvider";

export default function useSetHeaderHeight(location: any, headerRef: MutableRefObject<HTMLElement | null>) {
    const headerSize = useObserveSize(headerRef);

    // Sandbox Information
    const { isSandbox } = useSandbox();
    const estatePlanSummary = useContext(EstatePlanSummaryContext);
    const isInContext = !!estatePlanSummary; // Check if `EstatePlanSummaryContext` is in scope.

    const trueSandbox = isSandbox || (isInContext && estatePlanSummary?.isSandbox);

    const { isAdmin } = useDetermineAccess();
    const isAdvisorDashboard = location === "Clients";

    const additionalBannerOffset = trueSandbox
        ? (isAdvisorDashboard && isAdmin ?
                50 : // isSandbox, isAdvisorDashboard, isAdmin -- both whitelabel selector and sandbox mode banner appear
                20   // isSandbox (either !isAdvisorDashboard or !isAdmin) -- only sandbox mode banner appears
        )
        : (isAdvisorDashboard && isAdmin ?
            30 :   // !isSandbox, isAdvisorDashboard, isAdmin -- only whitelabel selector appears
            0);    // !isSandbox, (either !isAdvisorDashboard or !isAdmin) -- neither whitelabel selector nor sandbox mode banner appear

    useEffect(() => {
        if (headerSize?.height) {
            const root = document.querySelector(':root') as HTMLElement;
            root.style.setProperty('--header-height', `${headerSize.height}px`);
            const fontSizeString = window.getComputedStyle(document.documentElement).getPropertyValue('font-size');
            const fontSize = Number(fontSizeString.substring(0, fontSizeString.length - 2));
            root.style.setProperty('--header-underline-offset', `${((headerSize.height / 2) - ((0.875 * fontSize) - 5)) - (additionalBannerOffset)}px`);
        }
    }, [headerSize?.height]);

    return
}
