import {Country, State} from "../interfaces/customerAddressInterfaces";
import Api from './Api';
import DOMPurify from "dompurify";
import htmlReactParser from "html-react-parser";
import Definitions from '../definitions';
import Security from '../security';
import {DropDownMenuItem} from "../components/global/inputs/SelectInput";
import {getEstatePlanSummary} from "./EstatePlanHelper";
import LegalfileHelper from "./LegalfileHelper";
import {IPerson} from "../components/client/PeopleEditor";
import {showModal} from "./AlertUtils";
import {FacetPartner} from "../Partner/FacetPartner";
import {LifeSitePartner} from "../Partner/LifeSitePartner";
import {LinkPartner} from "../Partner/LinkPartner";
import {NoPartner} from "../Partner/NoPartner";


class EGClass {
    checkShouldShipSettings = (estatePlanSummary: any, whiteLabel: any | null | undefined = null): boolean => {
        // determine if we should add to shipping or not based on white label settings, NOTE: this is roughly the same logic found in shippingsHelper.checkShouldShip()
        let shouldShip = false;
        if (!whiteLabel) {
            whiteLabel = window.SERVER_DATA.whiteLabel;
        }
        const wlSettings = whiteLabel?.settings;
        if (wlSettings?.ship === "yes") {
            if (!wlSettings.docsToShip) {
                shouldShip = true; // If this object was not set then assume they wanted to ship it
            } else if (
                (wlSettings.docsToShip === "trusts and wills" && Definitions.trustTypes.concat([Definitions.estatePlanTypes.ConventionalWill]).includes(estatePlanSummary.estatePlanType)) ||
                (wlSettings.docsToShip === "trusts only" && Definitions.trustTypes.includes(estatePlanSummary.estatePlanType))
            ) {
                shouldShip = true;
            }
        }

        return shouldShip;
    };

    impersonateClient = async (estatePlanId: number, setUser: (user: any) => void, route: string|undefined = '/app/client') => {
        const response = await Api.put(`customers/estatePlans/${estatePlanId ? estatePlanId : 'clear'}/session`, {});
        if (response?.status === 200) {
            const response2: any = await Api.get('usercache');
            if (response2?.status === 200) {
                const newUser = await response.json();
                setUser(newUser);
                if (route) {
                    window.open(route, 'CLIENT_WINDOW');
                }
            }
        }
    }

    loadUserCache = async (estatePlanId: number | null = null, force: boolean = false) => {
        let queryString: string = '';
        queryString += `estatePlanId=${estatePlanId}`;
        queryString += force ? "force=1" : "";
        const url = `usercache?${queryString}`


        return await Api.get(url)
        .then((userCacheObject) => {
            return userCacheObject.json();
        })
        .catch((err) => {
            console.log('Error loading usercache', err);
            return err;
        })
    }

    formatNumberToCurrency = (number: number, currency: string = 'USD') => {
        if (!number) {
            number = 0;
        }
        const CurrencyFormatter = new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: currency,
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
        })

        return CurrencyFormatter.format(number);
    }

    get nameSuffix() {
        return [
            {label: 'None', value: ''},
            {label: 'Sr.', value: 'Sr.'},
            {label: 'Jr.', value: 'Jr.'},
            {label: 'II', value: 'II'},
            {label: 'III', value: 'III'},
            {label: 'IV', value: 'IV'},
            {label: 'V', value: 'V'},
            {label: 'Esq.', value: 'Esq.'},
            {label: 'M.D.', value: 'M.D.'},
            {label: 'Ph.D.', value: 'Ph.D.'}
        ]
    }

    parseHtml(html: string, options = {}) {
        const sanitizedText = DOMPurify.sanitize(html);
        return htmlReactParser(sanitizedText, options)
    }

    // dateToDateString(date: any) {
    //     if (!date) {
    //         return '';
    //     }
    //
    //     const utcDate = new Date(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate());
    //
    //     const day = utcDate.getUTCDate().toString();
    //     const month = (utcDate.getUTCMonth() + 1).toString();
    //     const year = utcDate.getUTCFullYear().toString();
    //
    //     return `${month}/${day}/${year}`;
    // }
    getLatestDocumentVersion(documentVersions: []): any {
        if (!documentVersions || documentVersions.length === 0) {
            return null;
        }

        const sortedVersions = documentVersions
            .slice() // Create a shallow copy to avoid modifying the original array
            .sort((a: any, b: any) => b.id - a.id); // Sort in descending order based on the 'id' property

        return sortedVersions[0];
    }

    // dateToDateString(date: any) {
    //     if (!date) {
    //         return '';
    //     }
    //
    //     const utcDate = new Date(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate());
    //
    //     const day = utcDate.getUTCDate().toString();
    //     const month = (utcDate.getUTCMonth() + 1).toString();
    //     const year = utcDate.getUTCFullYear().toString();
    //
    //     return `${month}/${day}/${year}`;
    // }

    isOwner(userId: number, legalFile: any) {
        if (!userId || !legalFile) {
            return false;
        }

        const owners = legalFile.people.filter((person: any) => [legalFile.ownerIdPrimary, legalFile.ownerIdSecondary].includes(person.id));
        const ownersUserIds = owners.map((person: any) => person.userId);
        return ownersUserIds.includes(userId);
    }

    hasOwnerAccess(userCache: any) {

        if (!userCache) {
            return false;
        }

        const roles: any[] = userCache?.systemPrivs?.roles;

        if (this.isMoreThanACustomer(roles)) {
            // TODO: This is kind of a hack, but let's try it.
            return true;
        }

        const estatePlanRoles: any[] = (userCache.estatePlans.find((estatePlan: any) => estatePlan.id === userCache.estatePlanId)?.roles) || [];

        // Only owners will have the automatically opened signing dialogue
        return estatePlanRoles.includes(Definitions.estatePlanRoles.owner.name);
    };

    shouldShowPricing = (whiteLabel: any, estatePlanSummary: any) => {
        let result = true;
        if (whiteLabel) {
            if (whiteLabel.settings?.showPricing === 'no') {
                result = false;
            }
            if (whiteLabel.settings?.showPricing === 'maybe' && estatePlanSummary?.settings?.hideClientPricing === '1') {
                result = false;
            }
            if (whiteLabel.collectionMethod === 'system' && whiteLabel.settings?.collectTriagePayment === '1') {
                result = false;
            }
        }
        return result;
    };

    shouldShowSigningDate(estatePlanSummary: any, alias: string | null = null, ownerId: number | null = null): {alias: string, ownerId: any|null, id:any} | null {
        // check to see if we should open the signing page of the trust automatically
        // const advisorId = estatePlanSummary.advisor?.id
        // const primaryUserId = estatePlanSummary.people.find((person: any) => person.id == estatePlanSummary.ownerIdPrimary)?.userId;
        // const secondaryUserId = estatePlanSummary.people.find((person: any) => person.id == estatePlanSummary.ownerIdSecondary)?.userId;
        // const userId = user.user.id
        // only owners and advisors will see the package signing dialog.
        // return null if filterByUser and not (userId and (userId in [advisorId, primaryUserId, secondaryUserId]))
        let packageToOpen = null;
        for (let i = 0; i < estatePlanSummary.packages.length; i++) {
            const thePackage = estatePlanSummary.packages[i];
            for (let j = 0; j < thePackage.status.length; j++) {
                const status = thePackage.status[j];
                if (alias && !(alias === thePackage.alias && ownerId === status.ownerId)) {
                    continue
                }

                if (status.dateSignedStatus === Definitions.packageDateSignedStatus.needed) {
                    packageToOpen = {alias: thePackage.alias, ownerId: status.ownerId, id: status.id};
                    break;
                }
            }
        }
        return packageToOpen
    };

    getLatestX(items: any[], sortField = 'updatedAt'): any | null {
        if (!items || items.length === 0) {
            return null;
        }

        const sortedItems = [...items].sort((a, b) => {
            if (a && b) {
                if (a[sortField] < b[sortField]) {
                    return 1;
                }
                if (a[sortField] > b[sortField]) {
                    return -1;
                }
            }
            return 0;
        });

        return sortedItems[0];
    }

    getLatestPackageVersion(packageVersions: any[], status: string | null = null): any | null {
        if (!packageVersions || packageVersions.length === 0) {
            return null;
        }

        let filteredVersions = packageVersions;
        if (status) {
            filteredVersions = packageVersions.filter((version) => version.status === status);
        }

        return this.getLatestX(filteredVersions, 'version');
    }

    isFirstPackageVersion(packageVersion: any, allVersions: any) {
        if (!packageVersion && !allVersions.length) {
            return null;
        }
        const sortedItems = allVersions.sort((a: any, b: any) => a.version - b.version);
        return packageVersion?.id === sortedItems[0]?.id
    }

    userHasAllowedRole(userRoles: string[], allowedRoles: string | string[]) {
        if (!Array.isArray(allowedRoles)) {
            allowedRoles = [allowedRoles];
        }

        for (let i = 0; i < allowedRoles.length; i++) {
            if ( userRoles.includes(allowedRoles[i]) ) {
                return true
            }
        }

        return false
    }

    sysAdminAllowedRoles = [Definitions.roles.sysAdmin.name]

    adminAllowedRoles = [...this.sysAdminAllowedRoles, Definitions.roles.admin.name];

    partnerAllowedRoles = [...this.adminAllowedRoles, Definitions.roles.admin.name, Definitions.roles.sysCustService.name, Definitions.roles.legalOps.name, Definitions.roles.accountManager.name, Definitions.roles.custService.name, Definitions.roles.advisor.name, Definitions.roles.attorney.name];

    clientAllowedRoles = [...this.partnerAllowedRoles, Definitions.roles.customer.name, Definitions.roles.collaborator.name];

    clientRequiredData = ['estatePlanSummary', 'legalFile'];

    isSysAdmin(userRoles: string[]) {
        return Security.isSysAdmin(userRoles)
    }

    isMoreThanACustomer(userRoles: string[]) {
        return Security.isMoreThanACustomer(userRoles)
    }

    isAdvisor(userRoles: string[]) {
        return Security.isAdvisor(userRoles)
    }

    isSysCustomerService(userRoles: string[]) {
        return Security.isSysCustomerService(userRoles)
    }

    isAccountManager(userRoles: string[]) {
        return Security.isAccountManager(userRoles)
    }

    isCustService(userRoles: string[]) {
        return Security.isCustService(userRoles)
    }

    isAttorney(userRoles: string[]) {
        return Security.isAttorney(userRoles)
    }

    isLegalOps(userRoles: string[]) {
        return Security.isLegalOps(userRoles)
    }

    isAdmin(userRoles: string[]) {
        return Security.isAdmin(userRoles)
    }

    areObjectsEqual(object1: object, object2: object) {
        let result = true
        Object.keys(object1).forEach( (key) => {
            // @ts-ignore
            if (object1[key] !== object2[key]) {
                result = false;
            }
        })
        return result;
    }

    loadEGPartner() {
        const fileName = window.SERVER_DATA.egPartnerClientFile;

        // TODO THIS WON'T WORK - need to pull the name of the file from the white label settings, then load it up. Either from the bundle or from a separate request.
        switch (fileName) {
            case "FacetPartner":
                // @ts-ignore
                window.EGPartner = new FacetPartner();
                break;
            case "LifeSitePartner":
                // @ts-ignore
                window.EGPartner = new LifeSitePartner();
                break;
            case "LinkPartner":
                // @ts-ignore
                window.EGPartner = new LinkPartner();
                break;
            case "NoPartner":
                // @ts-ignore
                window.EGPartner = new NoPartner();
                break;
            default:
                console.log("Partner file not found. Defaulting to NoPartner")
                // @ts-ignore
                window.EGPartner = new NoPartner();
                break;
        }
    }

    dateToDateString(date:any, shortYear?: boolean) {
        if (!date) {
            return '';
        }

        const year = date.getUTCFullYear();
        const month = date.getUTCMonth() + 1;
        const day = date.getUTCDate();

        if (shortYear) {
            const lastTwoDigits = (year % 100).toString().padStart(2, "0")
            return `${month}/${day}/${lastTwoDigits}`
        }

        return `${month}/${day}/${year}`;
    }

    dateToDateTimeString(date: Date | null): string {
        if (!date) {
            return '';
        }
        const options: Intl.DateTimeFormatOptions = {
            year: 'numeric',
            month: 'numeric',
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
        };
        return new Intl.DateTimeFormat('en-US', options).format(date);
    }

    dateToLocalDateString(date: Date | null): string {
        if (!date) {
            return '';
        }
        return date.toLocaleDateString();
    }


    dateToTimeString(date: Date) {
        if (!date) {
            return '';
        }

        const time = date.toLocaleTimeString('en-US', {
            hour: 'numeric',
            minute: 'numeric',
            second: undefined,
            hour12: true
        });

        return time.toLowerCase();
    }

    // getTrueOrFalse(string: '1' | '0' | true | false) {
    //     if (string === '1' || string === true) {
    //         return 'True';
    //     }
    //     else {
    //         return 'False';
    //     }
    // }

    getYesOrNo(string: '1' | '0' | true | false) {
        if (string === '1' || string === true) {
            return 'Yes';
        }
        else {
            return 'No';
        }
    }

    formatPeopleEditorViewData(value: any) {
        if(typeof value == "number" || (typeof value == "string" && value !== '1' && value !== '0')){
            return value;
        }
        if(typeof value == "object"){
            return value;
        }

        if (value === '1' || value === true || value === 1) {
            return 'Yes';
        }
        else if(value === '0' || value === false || value === 0) {
            return 'No';
        }

        return value;
    }

    getYesNo(value: any) {
        if(typeof value == "string"){
            if(value.length > 1){
                return value;
            }
        }
        if(typeof value == "object"){
            return value;
        }

        if (value === '1' || value === true || value === 1) {
            return 'Yes';
        }
        else {
            return 'No';
        }
    }

    formatFullName(person: any) {
        return this.getPersonFullName(person)
    }

    formatFullNameLastFirst(obj: any) {
        let a = [];
        if (obj?.lastName) {
            a.push(obj?.lastName + ',');
        }

        if (obj?.firstName) {
            a.push(obj?.firstName);
        }

        if (obj?.middleName) {
            a.push(obj?.middleName);
        }

        // The suffix isn't being used in the classic app. Uncomment here if we want to use it in redesign.
        // if (obj?.suffix) {
        //     a.push(', ' + obj?.suffix);
        // }

        return a.join(' ')
    }

    getPersonFullName(person: any) {
        if (!person) {
            return '';
        }
        if (person.type === Definitions.personTypes.entity) {
            return person.entity;
        }

        let nameArray = [];
        if (person.firstName) {
            nameArray.push(person.firstName);
        }
        if (person.middleName) {
            nameArray.push(person.middleName);
        }
        if (person.lastName) {
            nameArray.push(person.lastName);
        }

        let fullName = nameArray.join(' ')

        let suffix = person.suffix
        if (suffix) {

        }
        if (suffix) {
            if ( ['sr.', 'jr.'].includes(suffix.toLowerCase()) ) {
                fullName = fullName + ', ' + suffix
            }
            else {
                fullName = fullName + ' ' + suffix
            }
        }

        return fullName
    }

    startCase(string: string) {
        let word = string.toLowerCase();
        return word.charAt(0).toUpperCase() + word.slice(1);
    }

    camelCaseToWords(str: string) {
        return str
            .replace(/([a-z])([A-Z])/g, '$1 $2') // Insert space between lowercase and uppercase letters
            .toLowerCase(); // Convert the string to lowercase
    }

    async getCountiesForState(state: string, forDropdown: boolean) {
        const response = await Api.get(`counties/${state}`);
        if (response?.status === 200){
            let result = await response.json();
            if (forDropdown) {
                result = result.map((element: string) => {
                    return {label: element, value: element}
                })
            }
            return result;
        } else {
            showModal({
                message: "could not retrieve counties for state: " + response.status,
                title: 'Error',
                warningIcon: true,
            });
        }
    }

    getCountryStateList(country: 'US' | 'CA' | 'OT') {
        let returnValue: State[];
        if (country === 'CA') {
            returnValue = this.dropDownLists.provinceWithLabel;
        }
        else if (country === 'US') {
            returnValue = this.dropDownLists.stateWithLabel;
        }
        else {
            returnValue = [{label: 'Other', value: 'OT'}]
        }
        return returnValue;
    };

    getDefaultCountry(country: 'US' | 'CA' | 'OT'): Country {
        const returnValue = {
            value: country,
            stateType: 'State',
            stateTypeLower: 'state',
        }
        if (country === 'CA') {
            returnValue.stateType = 'Province';
            returnValue.stateTypeLower = 'province';
        }
        return returnValue;
    }

    getCurrentPersonId = (userCache: any, legalFile: any, scope: any, ownerId: number): number => {
        if(!userCache || !legalFile || !scope || ownerId === undefined){
            console.error("getCurrentPersonId() - BadRequest");
            return 0;
        }
        let personId = 0
        if (scope === 'individual'){
            personId = ownerId;
        }
        else { // It is joint
            // set the personId to the currently logged in user if they are an owner
            let userId = userCache?.user?.id;
            if (userId) {
                const person = legalFile.people.find((person: any) =>
                    person.userId === userId && (person.id === legalFile.ownerIdPrimary || person.id === legalFile.ownerIdSecondary)
                );
                if (person) {
                    personId = person.id;
                }
            }

            if (!personId) {
               personId = legalFile.ownerIdPrimary;
            }
        }
        return personId;
    }

    formatPhoneNumber = (input: string | null): string => {
        if (!input){
            return "";
        }
        let formattedNumber;
        const length = input.length;

        // Filter non numbers and limit to 10 digits
        const regex = () => input.replace(/[^0-9]/g, "").slice(0, 10);

        // Set area code with parenthesis around it
        const areaCode = () => `(${regex().slice(0, 3)})`;

        // Set formatting for first six digits
        const firstSix = () => `${areaCode()} ${regex().slice(3, 6)}`;

        // Dynamic trail as user types
        const trailer = (start: number) => `${regex().slice(start, regex().length)}`;

        if (length < 3) {
            formattedNumber = regex();
        } else if (length === 4) {
            formattedNumber = `${areaCode()} ${trailer(3)}`;
        } else if (length === 5) {
            formattedNumber = `${areaCode().replace(")", "")}`;
        } else if (length > 5 && length < 9) {
            formattedNumber = `${areaCode()} ${trailer(3)}`;
        } else if (length > 9) {
            formattedNumber = `${firstSix()}-${trailer(6)}`;
        } else {
            formattedNumber = input;
        }

        return formattedNumber;
    };

    stripNonDigits = (input: any) => {
        const chars = "1234567890"
        if(!input) {
            return;
        }
        input = input.toString();
        return input.replace(new RegExp('[^' + chars + ']+', 'g'), '');
    }

    saveCompleteLegalFile = async (completeLegalFile: any, isTriage = false) => {
        if (isTriage) {
            return;
        }
        await completeLegalFile.legalFilePrimary.save();
        await completeLegalFile.legalFileSecondary.save();
        await completeLegalFile.legalFileCommon.save();
        await completeLegalFile.refreshPeople();
    }

    getWhiteLabelName = () => {
        return window.SERVER_DATA.whiteLabelName;
    }

    getWhiteLabelId = () => {
        return window.SERVER_DATA.whiteLabelId;
    }

    capitalizeFirst = (s: string) => s.charAt(0).toUpperCase() + s.slice(1);

    capitalizeAll = (s: string) => s.toUpperCase();

    getAttorneyLabel = () => {
        // @ts-ignore
        return window.SERVER_DATA.defaultCountry === "CA" ? "Lawyer" : "Attorney";
    }

    getCountryLabelByValue = (countryValue: "US" | "CA") => {
        const countryWithLabel = this.dropDownLists.countryWithLabel;
        const item = countryWithLabel.find(item => item.value === countryValue);
        return item ? item.label : "Country not found";
    }

    // NOTE: There is a more modern version of this in the validation.ts file that you should use
    // isValidEmail(value: string, allowEmpty: boolean): boolean {
    //     const emailRegex: RegExp = new RegExp("^.+@.+\..{2,6}$", "i");
    //     if (allowEmpty && !value.trim()) {
    //         return true;
    //     }
    //     return emailRegex.test(value);
    // }

    getStateLabelByValue = (country: string, stateValue: string) => {
        const stateWithLabel = country === "US" ? this.dropDownLists.stateWithLabel :
            country === "CA" ? this.dropDownLists.provinceWithLabel : "Country not found";
        if (stateWithLabel === "Country not found") {
            return "Country not found";
        }
        const item = stateWithLabel.find(item => item.value === stateValue);
        return item ? item.label : `${country === "US" ? "State" : "Province"} not found`;
    }

    getCountryMenuItems = (country: any) => {
        let countryList = [...this.dropDownLists.countryWithLabel];
        const defaultCountry = this.getDefaultCountry(country).value
        if (defaultCountry === "CA") {
            countryList = [...this.dropDownLists.countryWithLabelCanada];
        }
        countryList.push({label: "Other Country", value: 'OT'})
        const menuItems: DropDownMenuItem[] = countryList.map((country) => {
            return {
                title: country.label,
                action: () => {
                    console.log(`Selected country: ${country.label}. Value: ${country.value}`)
                },
                value: country.value,
            };
        });
        return menuItems;
    };

    sortByFieldDescending = (jsonArray: any[], field: string) => {
        return jsonArray.sort((a, b) => {
            if (a[field] > b[field]) {
                return -1;
            }
            if (a[field] < b[field]) {
                return 1;
            }
            return 0;
        });
    }

    logout = async (listOfContextSetters:  ((newContext: any) => void)[] = []) => {
        // cleanup the user guide
        // @ts-ignore
        window.productFruits?.services.destroy();

        const redirectUrl = Api.serverRoot === "" ? "/" : Api.serverRoot;
        const response = await Api.get('logout');
        if (response?.status === 200) {
            if (Array.isArray(listOfContextSetters)) {
                listOfContextSetters.forEach( (contextSetter) => contextSetter(null))
            }
            window.location.href = redirectUrl;
        }
    }

    dropDownLists = {
        countryWithLabel: [
            {label: 'United States', value: 'US'},
            {label: 'Canada', value: 'CA'}
        ],
        countryWithLabelCanada: [
            {label: 'Canada', value: 'CA'},
            {label: 'United States', value: 'US'}
        ],
        provinceWithLabel: [
            {label: 'Alberta', value: 'AB'},
            {label: 'British Columbia', value: 'BC'},
            {label: 'Manitoba', value: 'MB'},
            {label: 'New Brunswick', value: 'NB'},
            {label: 'Newfoundland and Labrador', value: 'NL'},
            {label: 'Northwest Territories', value: 'NT'},
            {label: 'Nova Scotia', value: 'NS'},
            {label: 'Nunavut', value: 'NU'},
            {label: 'Ontario', value: 'ON'},
            {label: 'Prince Edward Island', value: 'PE'},
            {label: 'Quebec', value: 'QC'},
            {label: 'Saskatchewan', value: 'SK'},
            {label: 'Yukon', value: 'YT'}
        ],
        stateWithLabel: [
            {label: 'Alabama', value: 'AL'},
            {label: 'Alaska', value: 'AK'},
            {label: 'Arizona', value: 'AZ'},
            {label: 'Arkansas', value: 'AR'},
            {label: 'California', value: 'CA'},
            {label: 'Colorado', value: 'CO'},
            {label: 'Connecticut', value: 'CT'},
            {label: 'Delaware', value: 'DE'},
            {label: 'District of Columbia', value: 'DC'},
            {label: 'Florida', value: 'FL'},
            {label: 'Georgia', value: 'GA'},
            {label: 'Hawaii', value: 'HI'},
            {label: 'Idaho', value: 'ID'},
            {label: 'Illinois', value: 'IL'},
            {label: 'Indiana', value: 'IN'},
            {label: 'Iowa', value: 'IA'},
            {label: 'Kansas', value: 'KS'},
            {label: 'Kentucky', value: 'KY'},
            {label: 'Louisiana', value: 'LA'},
            {label: 'Maine', value: 'ME'},
            {label: 'Maryland', value: 'MD'},
            {label: 'Massachusetts', value: 'MA'},
            {label: 'Michigan', value: 'MI'},
            {label: 'Minnesota', value: 'MN'},
            {label: 'Mississippi', value: 'MS'},
            {label: 'Missouri', value: 'MO'},
            {label: 'Montana', value: 'MT'},
            {label: 'Nebraska', value: 'NE'},
            {label: 'Nevada', value: 'NV'},
            {label: 'New Hampshire', value: 'NH'},
            {label: 'New Jersey', value: 'NJ'},
            {label: 'New Mexico', value: 'NM'},
            {label: 'New York', value: 'NY'},
            {label: 'North Carolina', value: 'NC'},
            {label: 'North Dakota', value: 'ND'},
            {label: 'Ohio', value: 'OH'},
            {label: 'Oklahoma', value: 'OK'},
            {label: 'Oregon', value: 'OR'},
            {label: 'Pennsylvania', value: 'PA'},
            {label: 'Rhode Island', value: 'RI'},
            {label: 'South Carolina', value: 'SC'},
            {label: 'South Dakota', value: 'SD'},
            {label: 'Tennessee', value: 'TN'},
            {label: 'Texas', value: 'TX'},
            {label: 'Utah', value: 'UT'},
            {label: 'Vermont', value: 'VT'},
            {label: 'Virginia', value: 'VA'},
            {label: 'Washington', value: 'WA'},
            {label: 'West Virginia', value: 'WV'},
            {label: 'Wisconsin', value: 'WI'},
            {label: 'Wyoming', value: 'WY'}
        ],
        maritalStatus: [
            {label: 'Married', value: Definitions.maritalStatusType.married.value},
            {label: 'Single', value: Definitions.maritalStatusType.single.value},
            {label: 'Common Law', value: Definitions.maritalStatusType.common.value},
            // {label: 'Legally Separated', value: Definitions.maritalStatusType.separated.value} #NOTE: PLEASE DON'T RE-ADD any of these until all of the ramifications are understood
            // {label: 'Divorced', value: Definitions.maritalStatusType.divorced.value}
            // {label: 'Presently married, but filed for divorce', value: Definitions.maritalStatusType.divorcing.value}
            // {label: 'Civil Union', value: Definitions.maritalStatusType.civil.value}
            // {label: 'Registered Domestic Partnership', value: Definitions.maritalStatusType.married.value}
        ],
        howReferred: [
            {label: "Another User", value: 'currentUser'},
            {label: 'Financial Advisor', value: 'advisor'},
            {label: 'Friend', value: 'friend'},
            {label: 'Facebook', value: 'facebook'},
            {label: 'Twitter', value: 'twitter'},
            {label: 'Website', value: 'website'},
            {label: 'Article', value: 'article'},
            {label: 'Advertisement', value: 'advertisement'},
            {label: 'Other', value: 'other'},
        ],
    }

    saveTriage = async (completeLegalFile: any, setEstatePlanSummary: any, setLegalFile: any, isEditTriage: boolean = false, estatePlanSummary: any = null) => {
        let triage = {...completeLegalFile.triage.data};
        if (isEditTriage) {
            triage.isUpdate = '1';
            triage.originalData = {...estatePlanSummary.triage};
        }

        const response = await Api.put(`user/triage/${completeLegalFile.estatePlanId}`, triage)

        if (response?.status === 200) {
            const localEstatePlanSummary = await getEstatePlanSummary(completeLegalFile.estatePlanId);
            const localLegalFile = await LegalfileHelper.retrieveCompleteLegalFile(completeLegalFile.estatePlanId, null, completeLegalFile.scope);
            setLegalFile(localLegalFile);
            setEstatePlanSummary(localEstatePlanSummary);
            return localEstatePlanSummary;
        }
        else {
            showModal({
                message: "Cannot save triage",
                title: 'Error',
                warningIcon: true,
            });
        }
    }

    updateAmend = async (amendRef: any, value: boolean, ownerId: number, estatePlanId: number, alias: string) => {
        amendRef.current = value;
        const options = {type: 'PUT'};
        const queryString = `?ownerId=${ownerId ?? ''}&amend=${amendRef.current ? '1' : '0'}`;
        const url = `plan/${estatePlanId}/alias/${alias}/amend${queryString}`;
        try {
            await Api.put(url, options);
        } catch (err) {
            console.error('Failed to update amend state');
        }
    }

    toLowerRemoveSpaces = (val: string | undefined) => {
        if (val) {
            return val?.toLowerCase()?.replace(/\s/g, '');
        } else {
            return;
        }
    }

    removeSpaceCommaAndToLower = (val: string)=> {
        if (!val) return val;
        return val.toLowerCase()?.replace(/,/g, "")?.replace(/ /g, "")?.trim();
    }

    regexForExtractingFileExtensions = /(?:\.([^.]+))?$/;

    extractFileExtension = (filename: string) => {
        const match = this.regexForExtractingFileExtensions.exec(filename);
        if (match) {
            return match[1];
        }
        return '';
    }

    truncateFileNames = (fileName: string, desiredLength: number) => {
        let fileNameLocal = fileName;
        if (fileName.length > desiredLength) {
            const fileExtension = '.' + this.extractFileExtension(fileName);
            fileNameLocal = fileName.substring(0, fileName.length - fileExtension.length).substring(0, (desiredLength - 2) - fileExtension.length) + '..' + fileExtension;
        }
        return fileNameLocal;
    }

    isWhiteLabelShip = () => {
        return window.SERVER_DATA.whiteLabel.settings?.ship === 'yes'
    }

    formatPersonName = (person: any, legalFile: any) => {
        if (!person) {
            return
        }
        const filteredRoles = [
            {name: Definitions.estatePlanRoles.child.name, display: 'child'},
            {name: Definitions.estatePlanRoles.owner.name, display: 'spouse'},
            {name: Definitions.estatePlanRoles.priorSpouse.name, display: 'prior spouse'},
            {name: Definitions.estatePlanRoles.healthcarePOA.name, display: 'healthcare POA'},
            {name: Definitions.estatePlanRoles.financialPOA.name, display: 'financial POA'}
        ]

        let personName = Definitions.getPersonFullName(person)
        const otherPerson = legalFile.people?.find((element: IPerson) => Definitions.getPersonFullName(element).toLowerCase() === personName.toLowerCase() && element.id !== person.id)
        if (otherPerson) {
            let roles = otherPerson.estatePlanRoles.map((role: any) => role.name);
            roles = roles?.filter((role: any) => filteredRoles.map(filteredRole => filteredRole.name).includes(role))
            roles = roles?.map((role: any) => filteredRoles.find(item => item.name === role)?.display || '')
            if (roles.length) {
                personName += ` (${roles.join('/')})`
            }
        }
        return personName
    }

    formatCurrency = (price:any): string => {
        return price > 0 ?
            price.toLocaleString("en-US", {style:"currency", currency:"USD"}) :
            'FREE';
    }

    getSuffixMenuItems = (value: string, formMethods: any) => {
        if (formMethods.setValue) {
            return [
                {action: () => formMethods.setValue(value,''), title: 'None'},
                {action: () => formMethods.setValue(value,'Sr.'), title: 'Sr.'},
                {action: () => formMethods.setValue(value,'Jr.'), title: 'Jr.'},
                {action: () => formMethods.setValue(value,'II'), title: 'II'},
                {action: () => formMethods.setValue(value,'III'), title: 'III'},
                {action: () => formMethods.setValue(value,'IV'), title: 'IV'},
                {action: () => formMethods.setValue(value,'V'), title: 'V'},
                {action: () => formMethods.setValue(value,'Esq.'), title: 'Esq.'},
                {action: () => formMethods.setValue(value,'M.D.'), title: 'M.D.'},
                {action: () => formMethods.setValue(value,'Ph.D.'), title: 'Ph.D.'},
            ]
        }
        else {
            return [
                {action: () => formMethods(''), title: 'None'},
                {action: () => formMethods('Sr.'), title: 'Sr.'},
                {action: () => formMethods('Jr.'), title: 'Jr.'},
                {action: () => formMethods('II'), title: 'II'},
                {action: () => formMethods('III'), title: 'III'},
                {action: () => formMethods('IV'), title: 'IV'},
                {action: () => formMethods('V'), title: 'V'},
                {action: () => formMethods('Esq.'), title: 'Esq.'},
                {action: () => formMethods('M.D.'), title: 'M.D.'},
                {action: () => formMethods('Ph.D.'), title: 'Ph.D.'},
            ]
        }
    }

    checkJobStatusUntilComplete(
        jobId: number,
        timeRemaining: number = 300000
    ): Promise<number> {
        return new Promise(async (resolve, reject) => {
            const pollInterval = 2000; // 2 seconds
            let startTime = Date.now();

            while (Date.now() - startTime <= timeRemaining) {
                try {
                    const response = await Api.get(`jobs/${jobId}`); // Using a GET method
                    const data = await response.json();

                    if (data.state === 'active' || data.state === 'waiting') {
                        // The job is still running, wait for pollInterval before next check
                        await new Promise(resolve => setTimeout(resolve, pollInterval));
                    } else if (data.state === 'completed' || data.state === 'none') {
                        // Job is complete
                        resolve(jobId);
                        return;
                    } else {
                        // Job failed or in an unexpected state
                        reject(new Error('Job failed or in an unexpected state'));
                        return;
                    }
                } catch (error) {
                    // Handle errors or rejections
                    reject(error);
                    return;
                }
            }

            // If we exit the while loop because time ran out
            reject(new Error('Job check timed out'));
        });
    }


    handleTrimming = (event: any) => {
        return event.target.value.trim();
    }

    getDocumentStatusDisplay = (documentVersion: any) => {
        let updatedAt = "";
        if(!documentVersion) return 'Unknown';
        if (documentVersion?.statusChangedAt) {
            updatedAt = this.dateToDateTimeString(new Date(documentVersion?.statusChangedAt))
        }
        switch (documentVersion?.status){
            case Definitions.documentVersionStatuses.initial:
                return 'Not started';
            case Definitions.documentVersionStatuses.questionnaire:
                return 'Not yet drafted';
            case Definitions.documentVersionStatuses.generating:
                return 'Generating';
            case Definitions.documentVersionStatuses.drafted:
                return 'Ready for client review ' + updatedAt;
            case Definitions.documentVersionStatuses.readyToSign:
            // Changed this from Ready to Sign so it was not misleading when applied to documents that don't get signed.
                return 'Completed ' + updatedAt;
            case Definitions.documentVersionStatuses.signed:
                return 'Signed ' + updatedAt;
            case Definitions.documentVersionStatuses.signedUploaded:
                return 'Signed & Uploaded ' + updatedAt;
            default:
                return 'Invalid status: ' + documentVersion.status;
        }

    }

//    hasAllSignaturePages = (packageVersion: any) => {
//        let returnValue = true;
//
//        packageVersion.documentVersions.forEach((documentVersion: any) => {
//            let dbDocument = documentVersion.document;
//
//        })
//
////        returnValue = true
////        #all documents needs to be signed and uploaded in order to be complete, or have no signature requirements
////        for documentVersion in packageVersion.documentVersions
////            dbDocument = documentVersion.document
////            continue if dbDocument.alias.indexOf(Definitions.documentList.SignatureSuffix) != -1 or
////                dbDocument.alias.indexOf(Definitions.documentList.PackageSummarySuffix) != -1 or
////                dbDocument.alias.indexOf(Definitions.documentList.CompletePackageSuffix) != -1 or
////                dbDocument.alias.indexOf(Definitions.documentList.CoverInfoSuffix) != -1 or
////                dbDocument.alias.indexOf(Definitions.documentList.BoilerPlateSuffix) != -1
////            documentDef = _.find(Definitions.documents, {alias: dbDocument.alias})
////            continue unless documentDef #not likely but this might refer to an old document alias
////            hasSignaturePage = _.some(documentDef.systemFiles, (systemFile) -> systemFile.type == 'signature')
////            unless (documentVersion?.status == Definitions.documentVersionStatuses.signedUploaded and hasSignaturePage or
////                documentVersion?.status in [Definitions.documentVersionStatuses.readyToSign, Definitions.documentVersionStatuses.signed] and not hasSignaturePage)
////                    returnValue = false if hasSignaturePage
////
////        return returnValue
//    }

    hasAllSignaturePages = (packageVersion: any): boolean => {
        return Definitions.hasAllSignaturePages(packageVersion);
    }

    convertStringToBoolean = (value: string) => {
        if (!value) return false;
        return value.toLowerCase().trim() === "true";
    }

    displayEstatePlanType = (estatePlanType: string) => {
        switch(estatePlanType) {
            case Definitions.estatePlanTypes.None:
                return 'None'
            case Definitions.estatePlanTypes.ConventionalWill:
                return 'Will'
            case Definitions.estatePlanTypes.SinglePersonTrust:
                return 'Single Trust'
            case Definitions.estatePlanTypes.MarriedJointTrust:
                return 'Married Trust'
            case Definitions.estatePlanTypes.MarriedJointTrustAB:
                return 'Married Joint Trust A/B'
            case Definitions.estatePlanTypes.MarriedSeparateTrust:
                return 'Married Separate Trust'
            default:
                return 'None'
        }
    }

    isSeparateAssets = (packageAlias: string) => {
        if (!packageAlias) return false;
        return packageAlias === Definitions.packageList.AssetSchedulesSeparate;
    }

    getDemoWhiteLabelUrl = (advisorData: any) => {
        return advisorData?.demoUser?.whiteLabel?.url ??
            'https://demo.estateguru.com';
    }

    getDemoUsername = (advisorData: any) => {
        return advisorData?.demoUser?.username ??
            'demoestateguru';
    }

    getDemoPassword = (advisorData: any) => {
        return advisorData?.demoUser?.username ?
            '(same original password when you created your account)' :
            'estategurudemo';
    }

    getFileReadableName = (doc: any): string => {
        if (doc === "termsOfUse") {
            return "Terms of Use";
        } else if (doc === "privacyAgreement") {
            return "Privacy Agreement";
        } else if (doc === "partnershipAgreement") {
            return "Partnership Agreement";
        } else {
            return "Document Name not found"
        }

    }

    goToClassicView = async (user: any, href: string) => {
        // save user data preference
        Api.put(`user/${user?.id}/preferences`, {environment: 'classic'})
            .then(() => {
                window.location.href = href;
            })
    }

    convertArrayOfObjectsToCSV = (array: any[]) => {
        if (array.length === 0) {
            return '';
        }
        let result: string;

        const columnDelimiter = ',';
        const lineDelimiter = '\n';
        const keys = Object.keys(array[0]);

        result = '';
        result += keys.join(columnDelimiter);
        result += lineDelimiter;

        array.forEach(item => {
            let ctr = 0;
            keys.forEach(key => {
                if (ctr > 0) result += columnDelimiter;

                result += item[key];

                ctr++;
            });
            result += lineDelimiter;
        });

        return result;
    }

    downloadCSV = (array: any[], filename: string)=> {
        const link = document.createElement('a');
        let csv = this.convertArrayOfObjectsToCSV(array);
        if (csv == null) return;

        if (!csv.match(/^data:text\/csv/i)) {
            csv = `data:text/csv;charset=utf-8,${csv}`;
        }

        link.setAttribute('href', encodeURI(csv));
        link.setAttribute('download', filename + '.csv');
        link.click();
    }

}

const EG = new EGClass();
export default EG;