import {Outlet, useLocation} from 'react-router-dom';
import Header, {PartnerHeader} from '../../global/navigation/Header';

export default function Layout () {
    const location = useLocation();


    return (
        <>
            <div className={'inset-0 absolute flex flex-col overflow-hidden min-w-[1024px] md:overflow-x-auto sm:overflow-x-auto bg-secondary-100'}>
                <div className={'shadow-sm z-50'}>
                    {
                        location.pathname.includes('/app/partner') ?
                            <PartnerHeader />
                        :
                            <Header />
                    }
                </div>
                <div className={"flex justify-center w-full overflow-auto"}>
                    <div className={'flex-1 lg:max-w-[80%] xl:max-w-[60%] px-8 pt-6 pb-[66px]'}>
                        <Outlet />
                    </div>
                </div>
            </div>
        </>
    )
}