import React, { Fragment, ReactNode, useEffect, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { XMarkIcon } from '../icons/Icons';
import Divider from "../Divider";

interface commonProps {
    title: string | ReactNode;
    subtitle?: string | ReactNode;
    size: 'small' | 'medium' | 'large' | 'extraLarge';
    background: boolean;
    content: ReactNode;
    buttons: ReactNode[];
    open: boolean;
    setOpen: (open: boolean) => void;
    topMargin?: boolean;
    noCloseOutsideClick?: boolean;
    hideXCloseButton?: boolean;
    additionalIcons?: any[];
}

type conditionalProps =
    | {
    expansion: true;
    expansionOpen: boolean;
    setExpansionOpen: (open: boolean) => void;
    expansionContent: ReactNode;
    expansionSize?: 'small' | 'medium' | 'large';
    expansionTitle: string | ReactNode;
}
    | {
    expansion: false;
    expansionOpen?: never;
    setExpansionOpen?: never;
    expansionContent?: never;
    expansionSize?: never;
    expansionTitle?: never;
}

type Props = commonProps & conditionalProps;

export default function Drawer(props: Props) {
    const [closeWithDelay, setCloseWithDelay] = useState(false);
    const [showXCloseButton] = useState(!props.hideXCloseButton);

    useEffect(() => {
        if (props.expansionOpen === false) {
            setCloseWithDelay(false);
        }
    }, [props.expansionOpen]);

    const size = {
        small: 'max-w-sm',
        medium: 'max-w-md',
        large: 'max-w-xl',
        extraLarge: 'max-w-5xl',
    };

    const topMargin: string = props.topMargin ? 'top-headerHeight' : 'top-[0px]';

    const closeDrawer = () => {
        if (props.noCloseOutsideClick) return;
        if (props.expansionOpen) {
            setCloseWithDelay(true);
            props.setExpansionOpen(false);
        }
        props.setOpen(false);
    }

    const handleKeyPress = (event: any) => {
        if (event.key === 'Escape') {
            closeDrawer();
        }
    }

    useEffect(() => {
        document.addEventListener("keydown", handleKeyPress, false);

        return () => {
            document.removeEventListener("keydown", handleKeyPress, false);
        }
    }, [])

    return (
        <Transition.Root show={props.open} as={Fragment}>
            <Dialog as="div" className="relative z-10" onClose={() => {}}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-in-out duration-500"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave={`ease-in-out duration-500 ${closeWithDelay ? 'delay-500 sm:delay-700' : ''}`}
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div
                        className={`fixed inset-0 bg-overlay ${topMargin} transition-opacity`}
                        onClick={() => {
                            closeDrawer();
                        }}
                    />
                </Transition.Child>

                <div className="absolute inset-0 overflow-hidden">
                    <div className={`pointer-events-none fixed bottom-0 ${topMargin} right-0 flex max-w-full pl-10`}>
                        {props.expansion && (
                            <Transition.Root show={props.expansionOpen} as={Fragment}>
                                <Transition.Child
                                    as={Fragment}
                                    enter="transform transition ease-in-out duration-500 sm:duration-700"
                                    enterFrom="translate-x-full"
                                    enterTo="translate-x-0"
                                    leave="transform transition ease-in-out duration-500 sm:duration-700"
                                    leaveFrom="translate-x-0"
                                    leaveTo="translate-x-full"
                                >
                                    <Dialog.Panel className={`pointer-events-auto w-screen z-10 ${size[props.expansionSize || 'medium']} rounded-l-lg`}>
                                        <div className="flex h-full w-full flex-col bg-white shadow-xl rounded-l-lg">
                                            <div className="py-4 px-6 border-b border-neutralPrimary">
                                                <div className="flex justify-between items-center">
                                                    <Dialog.Title className="text-base font-semibold font-header leading-6 text-primary">
                                                        {props.expansionTitle}
                                                    </Dialog.Title>
                                                    <div className="ml-3 flex h-7 items-center">
                                                        <button
                                                            type="button"
                                                            className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                                                            onClick={() => props.setExpansionOpen(false)}
                                                            data-testid={"closePanel"}
                                                        >
                                                            <span className="sr-only">X Icon</span>
                                                            <XMarkIcon className="w-4" aria-hidden="true" />
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className={"my-5 flex-1 pb-20 px-6 overflow-y-auto sm:px-6 " + (props.background && 'bg-primary-100')}>{props.expansionContent}</div>
                                        </div>
                                    </Dialog.Panel>
                                </Transition.Child>
                            </Transition.Root>
                        )}

                        <Transition.Child
                            as={Fragment}
                            enter="transform transition ease-in-out duration-500 sm:duration-700"
                            enterFrom="translate-x-full"
                            enterTo="translate-x-0"
                            leave={`transform transition ease-in-out duration-500 sm:duration-700 ${closeWithDelay ? 'delay-500 sm:delay-700' : ''}`}
                            leaveFrom="translate-x-0"
                            leaveTo="translate-x-full"
                        >
                            <Dialog.Panel className={`pointer-events-auto w-screen ${size[props.size]} rounded-l-lg z-20`}>
                                <div className="flex h-full w-full flex-col bg-white shadow-xl rounded-l-lg">
                                    <div className="py-4 px-6 border-b border-neutralPrimary">
                                        <div className="flex justify-between items-center">
                                            <Dialog.Title className="text-base font-semibold font-header leading-6 text-primary">
                                                {props.title}
                                            </Dialog.Title>

                                            <div className={"flex right-0 space-x-10"}>
                                                { !!props.additionalIcons?.length && (
                                                    <div className={"flex right-0 space-x-6"}>
                                                        {props.additionalIcons.map((element) => {
                                                            return (element.icon);
                                                        })}
                                                    </div>
                                                )}

                                                { showXCloseButton &&
                                                    <div className="ml-3 flex h-7 items-center">
                                                        <button
                                                            type="button"
                                                            className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                                                            onClick={() => {
                                                                if (props.expansionOpen) {
                                                                    setCloseWithDelay(true);
                                                                    props.setExpansionOpen(false);
                                                                }
                                                                props.setOpen(false);
                                                            }}
                                                            data-testid={"closePanel"}
                                                        >
                                                            <span className="sr-only">X Icon</span>
                                                            <XMarkIcon className="w-4" aria-hidden="true"/>
                                                        </button>
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                    {/*TODO change the content to split out the subtitle (estate Plan name)*/}
                                    <div className={"pt-3"}>
                                        {props.subtitle && <div className={"px-6"}>
                                            {props.subtitle}
                                            <Divider color={'dark-grey'} margins={'compact'}/>
                                        </div>}
                                    </div>
                                    <div
                                        className={"flex-1 pb-20 px-6 overflow-y-auto sm:px-6"}>{props.content}</div>
                                    { props.buttons?.length ? (
                                        <div
                                            className={'p-4 sm:px-6 border-t border-neutralPrimary flex w-full bottom-0 bg-white justify-center gap-x-[12px] rounded-bl-lg bg-primary-100'}>
                                            {props.buttons.map((button, index) => {
                                                return (
                                                    <div key={index}
                                                    >
                                                        {button}
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    ): <></>}
                                </div>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
    )
}