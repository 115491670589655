import React, {createContext, useEffect, useState} from "react";
import Api from "../../helpers/Api";
import {EstatePlanSummaryContext} from "./EstatePlanSummaryProvider";
import {getEstatePlanSummary} from "../../helpers/EstatePlanHelper";

interface GuideProviderProps {
    children: React.ReactNode
}

interface IGuideSummary {
    trustChanges?: boolean;
    secondaryTrustChanges?: boolean;
    willChanges?: boolean;
    secondaryWillChanges?: boolean;
    primaryFPOAChanges?: boolean;
    secondaryFPOAChanges?: boolean;
    primaryHPOAChanges?: boolean;
    secondaryHPOAChanges?: boolean;
}

export const GuideContext = createContext<any>(null);

export const GuideProvider: React.FC<GuideProviderProps> = ({children }) => {
    const [estatePlanSummary, setEstatePlanSummary] = React.useContext(EstatePlanSummaryContext);
    const [guideSummary, setGuideSummary] = useState<IGuideSummary>({
        trustChanges: false,
        secondaryTrustChanges: false,
        willChanges: false,
        secondaryWillChanges: false,
        primaryFPOAChanges: false,
        secondaryFPOAChanges: false,
        primaryHPOAChanges: false,
        secondaryHPOAChanges: false,
    });

    useEffect(() => {
        if (estatePlanSummary?.settings?.guideSummary) {
            setGuideSummary({...estatePlanSummary?.settings?.guideSummary});
        }
    }, []);

    useEffect(() => {
        if (guideSummary && estatePlanSummary) {
            void saveGuideSummary();
        }
    }, [guideSummary]);

    async function saveGuideSummary() {
        await Api.put(`plan/${estatePlanSummary?.id}/guideSummary`, {...guideSummary})
        .then( async () => {
            const localEstatePlanSummary = await getEstatePlanSummary(estatePlanSummary.id);
            await setEstatePlanSummary(localEstatePlanSummary);
        })
    }

    return (
        <GuideContext.Provider value={[guideSummary, setGuideSummary]}>
            {children}
        </GuideContext.Provider>
    );
};