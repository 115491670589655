import React, { useState, useRef, useLayoutEffect, ReactElement } from 'react';
import ReactDOM from 'react-dom';
import {QuestionMarkCircleIcon} from "./icons/Icons";
import LearnMoreDrawer from "./drawers/LearnMoreDrawer";

// Helper function to generate unique IDs
const generateId = (() => {
    let count = 0;
    return () => `tooltip-${count++}`;
})();

interface Props {
    message?: string | ReactElement;
    extraClassNames?: string;
    drawerTitle?: string;
    children?: React.ReactNode;
}

const Tooltip: React.FC<Props> = ( props: Props ) => {
    const [isDrawerOpen, setIsDrawerOpen] = useState(false);
    const [isVisible, setIsVisible] = useState(false);
    const [tooltipId] = useState(generateId);
    const targetRef = useRef<HTMLDivElement>(null);

    useLayoutEffect(() => {
        if (isVisible) {
            document.body.style.overflow = ''; // We might need to add 'hidden' back in here, tbh I'm not sure why I added it in the first place

            const tooltip = document.getElementById(tooltipId);
            const target = targetRef.current;
            if (tooltip && target) {
                const targetRect = target.getBoundingClientRect();
                const tooltipRect = tooltip.getBoundingClientRect();
                const viewportWidth = window.innerWidth;
                const viewportHeight = window.innerHeight;

                // Set initial position near the target element
                let top = targetRect.bottom + window.scrollY + 10;
                let left = targetRect.left + window.scrollX;

                // Adjust for right overflow
                if (left + tooltipRect.width > viewportWidth) {
                    left = viewportWidth - tooltipRect.width - 10;
                }

                // Adjust for left overflow
                if (left < 0) {
                    left = 10;
                }

                // Adjust for bottom overflow
                if (top + tooltipRect.height > viewportHeight) {
                    top = targetRect.top + window.scrollY - tooltipRect.height - 10;
                }

                // Adjust for top overflow
                if (top < 0) {
                    top = targetRect.bottom + window.scrollY + 10;
                }

                tooltip.style.top = `${top}px`;
                tooltip.style.left = `${left}px`;
            }
        }
        else {
            document.body.style.overflow = '';
        }
    }, [isVisible]);

    return (
        <>
            <div
                ref={targetRef}
                className={`flex items-center ${props.extraClassNames ? props.extraClassNames : ""}`}
                onMouseEnter={() => setIsVisible(true)}
                onMouseLeave={() => setIsVisible(false)}
            >
                {props.children ? props.children : <QuestionMarkCircleIcon />}
            </div>

            {isVisible && ReactDOM.createPortal(
                <div
                    id={tooltipId}
                    className="absolute p-4 rounded bg-tertiary-900 text-neutralSecondary border border-tertiary-100 text-xs max-w-[200px] z-50"
                >
                    {props.message}
                </div>,
                document.body
            )}

            {/* TODO -- Do we need this anymore? the only instance using this prop is commented out... */}
            {props.drawerTitle &&
                <LearnMoreDrawer
                    title={props.drawerTitle}
                    size={'small'}
                    open={isDrawerOpen}
                    setOpen={(isOpen) => setIsDrawerOpen(isOpen)}
                    topMargin={false}
                />
            }
        </>
    );
};

export default Tooltip;
