import React from "react";
import {useSandbox} from "./UtilsProvider";

const SandboxBanner: React.FC = () => {
    const { isSandbox } = useSandbox();

    if (!isSandbox) return null;

    return (
        <div className="w-full bg-warning text-secondary-100 p-1 z-10 flex justify-between items-center">
            <span className={"text-xl flex w-full items-center justify-center"}>Sandbox mode</span>
        </div>
    );
};

export default SandboxBanner;
