import API from "./Api";
import _ from "lodash";
import Definitions from '../definitions'
import cloneDeep from 'lodash/cloneDeep';
import { showModal, showAlert } from './AlertUtils';

let serverRoot = process.env.NODE_ENV == "development" ? process.env.REACT_APP_EXTERNAL_SERVER_ROOT : (window as any).serverRoot;

export class CompleteLegalFile {
    estatePlanId: number;
    scope: string; // scope and ownerId correspond to how it was requested,
    ownerId: number | null
    ownerIdPrimary: number | null;
    ownerIdSecondary: number | null;
    legalFileCommon: LegalFileModel;
    legalFilePrimary: LegalFileModel;
    legalFileSecondary: LegalFileModel;
    people: [any]; // Array of person types
    triage: any;
    state: string | null;

    constructor(estatePlanId: number, ownerId: number | null, scope: string, data: any) {
        this.estatePlanId = estatePlanId;
        this.ownerId = ownerId;
        this.scope = scope;

        this.ownerIdPrimary = data.ownerIdPrimary;
        this.ownerIdSecondary = data.ownerIdSecondary;
        this.people = data.people;

        // Put these into emulated legalFileModel from backbone
        this.triage = new LegalFileModel({...data.triage, estatePlanId: estatePlanId, ownerId: null, country: window.SERVER_DATA.defaultCountry});
        this.legalFileCommon = new LegalFileModel({
            ...data.legalFileCommon,
            estatePlanId: estatePlanId,
            ownerId: null,
            legalFileType: "common"
        });
        this.legalFilePrimary = new LegalFileModel({
            ...data.legalFilePrimary,
            estatePlanId: estatePlanId,
            ownerId: data.ownerIdPrimary,
            legalFileType: "primary"
        });
        this.legalFileSecondary = new LegalFileModel({
            ...data.legalFileSecondary,
            estatePlanId: estatePlanId,
            ownerId: data.ownerIdSecondary,
            legalFileType: "secondary"
        });
        this.state = data.state;
    }

    public async refreshPeople(): Promise<void> {
        let data: any = {joint: this.scope == 'joint', ownerId: this.ownerId}
        let queryString = new URLSearchParams(data).toString()
        let url = `legalfile/${this.estatePlanId}/people?${queryString}`;

        let res = await API.get(url);
        if(res.status == 200){
            this.people = await res.json();
        }else{
            showModal({
                message: 'could not retrieve data',
                title: 'Error',
                warningIcon: true,
            });
        }
    }

    public async save(): Promise<void> { // Saves everything
        // TODO only patch? - send only the changes
        await this.legalFileCommon.save();
        await this.legalFilePrimary.save(); // TODO only patch?
        if (this.ownerIdSecondary)
            await this.legalFileSecondary.save(); // TODO only patch?
        await this.refreshPeople()
    }

    public getLegalFile(whichLegalFileModel: string, currentPersonId: number | null = null, withName = false): LegalFileModel | any | null {
        let legalFileModel = this.legalFileCommon;
        let legalFileName = 'common';
        switch (whichLegalFileModel) {
            case 'individual': { //Note: same as self
                if (currentPersonId == this.ownerIdPrimary) {
                    legalFileModel = this.legalFilePrimary;
                    legalFileName = 'primary';
                } else if (currentPersonId == this.ownerIdSecondary) {
                    legalFileModel = this.legalFileSecondary
                    legalFileName = 'secondary'
                }
                break;
            }
            case 'joint':
                legalFileModel = this.legalFileCommon
                legalFileName = 'common'
                break;
            case 'common':
                legalFileModel = this.legalFileCommon
                legalFileName = 'common'
                break;
            case 'primary':
                legalFileModel = this.legalFilePrimary
                legalFileName = 'primary'
                break;
            case 'secondary':
                legalFileModel = this.legalFileSecondary
                legalFileName = 'secondary'
                break;
            case 'self': // NOTE: same as individual
                if (this.ownerIdPrimary == currentPersonId) {
                    legalFileModel = this.legalFilePrimary
                    legalFileName = 'primary'
                } else if (this.ownerIdSecondary == currentPersonId) {
                    legalFileModel = this.legalFileSecondary
                    legalFileName = 'secondary'
                }
                break;
            case 'spouse':
                if (this.ownerIdSecondary) { //check to see if there is a second file
                    if (this.ownerIdPrimary == currentPersonId) {
                        legalFileModel = this.legalFileSecondary
                        legalFileName = 'secondary'
                    } else if (this.ownerIdSecondary == currentPersonId) {
                        legalFileModel = this.legalFilePrimary
                        legalFileName = 'primary'

                    }
                }
                break;
            case 'triage':
                legalFileModel = this.triage;
                legalFileName = 'triage';
                break;
            default: {
                //console.trace()
                // console.log("getLegalFile: whichLegalFileModel is invalid: #{whichLegalFileModel}")
            }
        }
        if (withName) {
            return {legalFileModel: legalFileModel, name: legalFileName}
        } else {
            return legalFileModel
        }
    }

    public getPerson(personId: number | string | null): any { // TODO person class
        return this.people.find((person) => person.id == parseInt(<string>personId));  // TODO test this
    }

    public getPersonByUserId(personId: number | string | null): any { // TODO person class
        return this.people.find((person) => person.userId == parseInt(<string>personId));  // TODO test this
    }

    public getPrimaryPerson(): any {
        return this.getPerson(this.ownerIdPrimary);
    }

    public getSecondaryPerson(): any {
        return this.getPerson(this.ownerIdSecondary);
    }

    public getSelf(currentPersonId: number) {
        return this.getPerson(currentPersonId);
    }

    public getSpouse(currentPersonId: number | null): any {
        let spouseId: number | null = null;

        // if (this.triage.maritalStatus in ['SEPARATED', 'DIVORCING']) {
        //     spouseId = this.getLegalFile('common')?.get(Definitions.legalFileFields.priorSpouse.name) ? [0]?.id
        // }

        if (currentPersonId == this.ownerIdPrimary)
            spouseId = this.ownerIdSecondary
        else if (currentPersonId == this.ownerIdSecondary)
            spouseId = this.ownerIdPrimary

        if (spouseId)
            return this.getPerson(spouseId);
        else
            return null
    }

    public getSelfAndSpouse(relationshipType: string, currentPersonId: number | null, spouseId?: number | string | null) {
        //values are 'current', 'selfPrevious', 'spousePrevious', 'selfUnmarried', 'spouseUnmarried'
        //default to the current user and current spouse
        let currentOwnerId = currentPersonId;
        let otherOwnerId = (currentOwnerId == this.ownerIdPrimary ? this.ownerIdSecondary : this.ownerIdPrimary); // currentSpouse id
        let isPriorSpouse = false
        let selfId: number | null;
        let whichLegalFile: string;

        //note: we set the whichLegalFile to self even for spouses. This is because we are reassigning the selfId
        switch (relationshipType) {
            case 'self':
                selfId = currentOwnerId;
                spouseId = otherOwnerId;
                whichLegalFile = 'self';
                break;
            case 'spouse':
                selfId = otherOwnerId;
                spouseId = currentOwnerId;
                whichLegalFile = 'self';
                break;
            case 'current':
                selfId = currentOwnerId;
                spouseId = otherOwnerId;
                whichLegalFile = 'joint';
                if (!selfId && !spouseId) throw 'current relationship requires selfId and spouseId';
                break;
            case 'selfPrevious':
                selfId = currentOwnerId;
                isPriorSpouse = true;
                whichLegalFile = 'self';
                if (!selfId && !spouseId) throw 'selfPrevious relationship requires selfId and spouseId';
                break;
            case 'spousePrevious':
                selfId = otherOwnerId;
                isPriorSpouse = true;
                whichLegalFile = 'self';
                if (!selfId && !spouseId) throw 'selfPrevious relationship requires selfId and spouseId';
                break;
            case 'selfUnmarried':
                selfId = currentOwnerId;
                spouseId = null;
                whichLegalFile = 'self';
                break;
            case 'spouseUnmarried':
                selfId = otherOwnerId;
                spouseId = null;
                whichLegalFile = 'self';
                break;
            case 'selfSeparated':
                selfId = currentOwnerId;
                isPriorSpouse = true;
                whichLegalFile = 'joint';
                if (!selfId && !spouseId) throw 'selfSeparated relationship requires selfId and spouseId';
                break;
            default:
                throw `Invalid relationship type: ${relationshipType}`;
        }

        if (!selfId) throw 'current relationship requires selfId';

        let self = this.people.find((person) => person.id === selfId);
        let spouse = null
        if (spouseId == 'new') {
            spouse = {id: null, firstName: '', middleName: '', lastName: '', suffix: ''}
        } else if (spouseId) {
            spouse = this.people.find((person) => person.id == spouseId)
            if (!spouse) throw `Unable to find spouse for id: ${spouseId}`;
        }

        let legalFileModel = this.getLegalFile(whichLegalFile, selfId)
        return {
            legalFileModel: legalFileModel,
            self: self,
            spouse: spouse,
            isPriorSpouse: isPriorSpouse
        }
    }

    getMinorChildren(group: string, currentPersonId: number | null, childField: any = null): any[] { //defaults to 'child' unless another legalfile field is specified  // TODO create LegalFileField interface
        if (!childField) {
            childField = Definitions.legalFileFields.child;
        }

        //minors are any who are under 18
        let cutOffDate = new Date();
        cutOffDate.setFullYear(cutOffDate.getFullYear() - 18);

        let minorChildren = [];
        let legalFilesToSearch: string[];
        switch (group) {
            case 'all':
                legalFilesToSearch = ['common', 'primary', 'secondary'];
                break;
            case 'allPrimary':
                legalFilesToSearch = ['common', 'primary'];
                break;
            case 'allSecondary':
                legalFilesToSearch = ['common', 'secondary'];
                break;
            case 'joint':
                legalFilesToSearch = ['common'];
                break;
            case 'primary':
                legalFilesToSearch = ['primary'];
                break;
            case 'secondary':
                legalFilesToSearch = ['secondary'];
                break;
            case 'individual':
                legalFilesToSearch = []
                if (currentPersonId == this.ownerIdPrimary) legalFilesToSearch.push('primary');
                if (currentPersonId == this.ownerIdSecondary) legalFilesToSearch.push('secondary');
                break;
            case 'allCurrent':
                legalFilesToSearch = ['common'];
                if (currentPersonId == this.ownerIdPrimary) legalFilesToSearch.push('primary')
                if (currentPersonId == this.ownerIdSecondary) legalFilesToSearch.push('secondary')
                break;
            default:
                console.log("invalid group for getMinorChildren");
                legalFilesToSearch = [];
                break;
        }

        for (let legalFileName of legalFilesToSearch) {
            let legalFileModel = this.getLegalFile(legalFileName)
            let children: any[] = legalFileModel?.get(childField.name) ?? [];
            for (let childIndex in children) {
                let child = children[childIndex];
                //only include those that are minors or if their birthdate is unknown
                let childRecord = this.people.find((person) => person.id == child.id);
                if (!childRecord) {
                    console.log('Missing child record for id: ' + child.id);
                    continue;
                }

                let childData = _.extend({...child}, childRecord)
                let birthdate: Date | null = null;
                if (childData.birthdate) birthdate = new Date(childData.birthdate);
                if (childData.deceased != '1' && (!birthdate || birthdate.getTime() > cutOffDate.getTime())) {
                    minorChildren.push({legalFileName: legalFileName, child: childData, childIndex: childIndex})
                }
            }
        }
        return minorChildren
    }
}

export class LegalFileModel {
    public data: any; // TODO this is the data object that will be used by REACT props
    public estatePlanId: number;
    public ownerId: number;
    public updateTimestamp: boolean = true;
    public id: any | null;

    constructor(data: any) {
        // TODO - verify that estatePlanId and ownerId are passed in (ownerId can be null)
        this.estatePlanId = data.estatePlanId;
        this.ownerId = data.ownerId;
        this.data = data;
        this.id = data.id;
    }

    // Other class methods...
    public get(key: string): any {
        return this.data[key]
    }

    public set(key: string, value: any): void {
        this.data[key] = value
    }

    public setUpdateTimestamp(value: boolean): void {
        this.updateTimestamp = value
    }

    public async save(options: any = null): Promise<void> { // TODO: might not do this handle saving with data passed in. Copy the data from the passed in object into this.data
        if (options?.triage) {
            return;
        }
        let url = this.getBaseUrl();
        // determine if we are doing a put or post
        if (this.id) {
            url += '/' + this.id
            let res = await API.put(url, this.data);
            if (res.status == 200){
                this.data = await res.json();
            }else{
                showModal({
                    message: 'could not update data',
                    title: 'Error',
                    warningIcon: true,
                });
            }

        } else {
            let res = await API.post(this.data, url);
            if (res.status == 200){
                this.data = await res.json();
            }else{
                showModal({
                    message: 'could not update data',
                    title: 'Error',
                    warningIcon: true,
                });
            }
        }
        this.updateTimestamp = true;
    }

    public async fetch(): Promise<void> {
        let url = this.getBaseUrl();
        this.data = await API.get(url);
    }

    private getBaseUrl(): string {
        let result = `legalfile?estatePlanId=${this.estatePlanId}`;
        if (this.ownerId) result += `&ownerId=${this.ownerId}`;
        result += `&updatetimestamp=${(this.updateTimestamp ? '1' : '0')}`
        return result
    }
}

class LegalFileHelper {



    // New method returns a promise so that we can use await instead of a call back
    retrieveCompleteLegalFile = async (estatePlanId: number, ownerId: number | null, scope: string): Promise<any> => {
        const url = `legalfile/${estatePlanId}?joint=${scope}&ownerId=${ownerId}`
        const response: any = await API.get(url);
        if (response.status === 200) {
            const res = await response.json();
            return new CompleteLegalFile(estatePlanId, ownerId, scope, res);
        }
        else if (response.status === 401) {
            return null;
        }
    }
}

export function cloneCompleteLegalFile (legalFile: CompleteLegalFile) {
    return new CompleteLegalFile(legalFile.estatePlanId, legalFile.ownerId, legalFile.scope, {
        ownerIdPrimary: legalFile.ownerIdPrimary,
        ownerIdSecondary: legalFile.ownerIdSecondary,
        people: cloneDeep(legalFile.people),
        triage: cloneDeep(legalFile.triage.data),
        legalFileCommon: cloneDeep(legalFile.legalFileCommon.data),
        legalFilePrimary: cloneDeep(legalFile.legalFilePrimary.data),
        legalFileSecondary: cloneDeep(legalFile.legalFileSecondary.data),
    })
}

export default new LegalFileHelper();