import React from "react";
import Button, {ButtonType} from "../buttons/Button";
import Drawer from "./Drawer";
import {ChevronRightIcon} from "../icons/Icons";
import {Link} from "react-router-dom";

interface Props {
    title: string;
    size: 'small' | 'medium' | 'large';
    open: boolean;
    setOpen: (open: boolean) => void;
    goBackButton?: boolean;
    alert?: boolean;
    defaultEmail?: string;
    topMargin?: boolean;
}

const LearnMoreDrawer = (props: Props) => {

    const hpoaContent = (
        <>
            <p className={"mb-6"}>
                This package includes the following documents:
            </p>

            <ul>
                <li>
                    HEALTH CARE POWER OF ATTORNEY - This document includes a place for you to name the person(s) who will make healthcare decisions for you if you are unable to do so for yourself. It also includes a section where you can outline specific guidance to healthcare providers about your wishes about the type and level of terminal care you desire.
                    <b> Please note: We provide you with the document specific to your state, however, we do not have you complete it with an online questionnaire. We urge you to complete this document with the guidance of your medical professional and loved ones.</b>
                </li>
                <li>
                    HIPAA RELEASE FORM - This document authorizes healthcare providers to provide your medical records to your healthcare agent so that they can adequately perform the job they are required to do on your behalf.
                </li>
                <li>
                    INTENT TO RETURN - This document declares that even though you may not be living in your home (e.g., you go into a nursing home), you still consider it your primary residence and you intend to live there again if your health allows.
                    The statement’s primary purpose is to help you gain Medicaid eligibility by preventing your home from counting against the Medicaid asset limit. All Medicaid applicants must meet an asset limit (and an income limit) to be financially eligible, and the value of a home would put most people over their state’s asset limit.
                </li>
            </ul>
        </>
    );

    const fpoaContent = (
        <div className={"flex flex-col space-y-6"}>
            <p>
                A Financial Power of Attorney empowers someone to make financial decisions for you if you are incapacitated or otherwise incapable of making them for yourself. Since this person will have the legal authority to act on your behalf, it's vital that this person is trustworthy and will act in your best interests.
            </p>

            <p>
                General durable power of attorney documents are in effect as soon as they are signed. They remain in force until the death of the principal or until the document is revoked. This means that the agent you name can make decisions and act even when you are competent and able to act for yourself. Our Financial POA documents are General Durable Power of Attorney documents.
            </p>

            <p>
                Your Financial Power of Attorney document authorizes the agent you named to manage or act on financial assets not titled in the name of the client's Revocable Trust. Any assets that are funded into a revocable trust are managed by the trustee of the trust, not the financial power of attorney agent.
            </p>
        </div>
    );

    const trustContent = (
        <>
            <p className={"mb-6"}>
                A revocable living trust, also frequently called a living trust or revocable trust, is a legal document in which you allow a trustee to manage designated assets for you and your beneficiaries during your lifetime. The trust is changeable and can provide more privacy than a will.
            </p>

            <ul className={"list-disc ml-6 mb-6"}>
                <li>A living trust or revocable living trust can help your estate and heirs avoid the hassle and costs of probate. However, a trust cannot designate guardianship for minor children, which is why wills and living trusts are often used together as part of an estate plan.</li>
                <li>Living trusts or revocable living trusts are trusts established during one’s lifetime, as opposed to testamentary trusts, which are created upon one’s death</li>
                <li>Technically, living trusts can be revocable or irrevocable. However, people often refer to revocable living trusts or revocable trusts simply as “living trusts.”</li>
            </ul>
        </>
    );

    const marriedJointTrustContent = (
        <>
            <p className={"mb-6"}>
                A joint revocable living trust is created by a married couple to hold and manage their assets during
                their lifetimes and distribute those assets to their beneficiaries upon their death. The terms of the
                trust can be altered or the trust can be terminated at any time during the couple's lifetime. The
                primary purposes of a Joint Revocable Living Trust are to avoid probate, to provide flexibility and
                potential tax benefits for married couples, and to ensure the efficient distribution of assets to the
                couple's chosen beneficiaries.
            </p>

            <p className={"mb-6"}>
                Once both spouses have passed away, the beneficiaries can receive their inheritance more quickly and
                without the potential delays and costs associated with probate. A joint revocable living trust is
                private with nothing becoming public record.
            </p>
        </>
    );

    const marriedSeparateTrustContent = (
        <>
            <p className={"mb-6"}>
                A married separate trust is a trust created and owned by just one spouse in a marriage. Getting married
                separate trusts can be a wise option for couples who own separate property, either from previous
                marriages or relationships, or from a family inheritance. They also are beneficial if a married couple
                has a prenuptial or postnuptial agreement that dictates that each spouse’s property and earnings should be kept
                separate.
            </p>

            <p className={"mb-6"}>
                As the grantor of your trust, you still retain ownership of assets and can modify the conditions and
                beneficiaries of your trust at any time. A revocable living trust is used to avoid probate at your
                death—passing your assets on to your beneficiaries without probate delays and fees. A revocable living
                trust also protects both your privacy and the privacy of the beneficiaries of your trust.
            </p>
        </>
    );

    const willContent = (
        <>
            <p className={"mb-6"}>
                A will is a legal document that expresses your wishes regarding the distribution of your property, who
                will manage your estate after you die, and who you want to be guardians of any minor children after
                your death. When you pass away, your will needs to be presented to the probate court. The court will
                examine your will to find who you’ve named to represent your estate throughout the probate proceedings.
                This person is your executor/personal representative and will be officially appointed to their position
                by the court. The court will oversee the administration of your estate. Your will becomes public record
                once you die, and your estate goes through probate.
            </p>
        </>
    );

    const changeStateInfo = (
        <>
            {/*TODO: get actual language*/}
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
            <Link to={'/app/client/update-my-plan'} className={'text-primary'} ><div className={'flex'}><span>I want to update my documents </span><ChevronRightIcon className={'w-4'} /></div></Link>
        </>
    );

    const priorTrustContent = (
        <>
            <p className={'text-lg'}>We will create new Power of Attorney documents and Wills to replace any you might already have.</p>
            <p className={'mt-6 text-lg'}>If you already have a living trust we will determine whether or not to create a new one for you or simply amend the one you have.</p>
        </>

    )

    const ageGenderContent = (
        <>
            <p className={'text-lg'}>We ask about your age to get a clear picture of our users. We ask about gender so we know the pronouns to use in your documents.</p>
        </>

    )

    const separateAssetsContent = (
        <>
            <p className={'text-lg'}>Assets you and your spouse do not own together are known as “separate property assets.”
                Examples of separate property include inheritances, assets owned before marriage, etc.</p>
            <p className={'mt-6 text-lg'}>If you had assets prior to marriage and did not combine
                them with your spouse's assets (referred to as 'commingling'), they would be considered separate property assets.</p>
        </>
    )

    const assetsContent = (
        <p className={'text-lg'}>Assets Content.</p>
    )

    const deedsContent = (
        <p className={'text-lg'}>Deeds Content.</p>
    )

    const noAccessContent = (
        <p className={'text-lg'}>No Access Content.</p>
    )

    const noTrustContent = (
        <p className={'text-lg'}>No Trust Content.</p>
    )

    const content = {
        'Married Trust': {
            content: marriedJointTrustContent,
        },
        'Married Trust (AB)': {
            content: marriedJointTrustContent, // content is the same for MJT and MJT(AB)
        },
        'Single Trust': {
            content: trustContent,
        },
        'Married Separate Trust': {
            content: marriedSeparateTrustContent,
        },
        'Simple Will': {
            content: willContent,
        },
        'Will': {
            content: willContent,
        },
        'Financial Power of Attorney': {
            content: fpoaContent,
            title: 'Financial Power of Attorney Package'
        },
        'Healthcare Power of Attorney': {
            content: hpoaContent,
            title: 'Healthcare Power of Attorney Package'
        },
        'Power of Attorney for Property': {
            content: trustContent,
        },
        'Power of Attorney for Personal Care': {
            content: trustContent,
        },
        "Why can't I change my state?": {
            content: changeStateInfo,
        },
        'Do you already have any of the following documents in place?': {
            content: priorTrustContent,
        },
        'Asset Schedule': {
            content: separateAssetsContent,
        },
        'Why do you need my date of birth and gender?': {
            content: ageGenderContent,
        },
        'Asset Schedule[s]': {
            content: assetsContent,
        },
        'Deeds': {
            content: deedsContent,
        },
        'No Access': {
            content: noAccessContent,
        },
        'No Trust': {
            content: noTrustContent,
        },
    }

    return (
        <Drawer
            title={props.title}
            size={props.size}
            background={false}
            // @ts-ignore
            content={content[props.title]?.content}
            // @ts-ignore
            buttons={[<Button
                onClick={() => props.setOpen(false)}
                buttonType={ButtonType.Primary}
            >
                Close
            </Button>]}
            open={props.open}
            setOpen={props.setOpen}
            topMargin={props.topMargin ? props.topMargin : undefined}
            expansion={false}
        />
    )
};

export default LearnMoreDrawer;