import React from 'react';
import { Switch } from '@headlessui/react';
import {ControllerRenderProps} from "react-hook-form";

function classNames(...classes: string[]) {
    return classes.filter(Boolean).join(' ')
}

const getSwitchSize = (size?: string): string => {
    switch(size) {
        case "sm": return "h-3 w-6"
        case "md": return "h-6 w-11"
        case "lg": return "h-8 w-14"
        default: return "h-6 w-11"
    }

}

const getInnerSwitchSize = (size?: string): string => {
    switch(size) {
        case "sm": return "h-2 w-2"
        case "md": return "h-5 w-5"
        case "lg": return "h-7 w-7"
        default: return "h-5 w-5"
    }
}

const getTranslateValue = (size?: string): string => {
    switch(size) {
        case "sm": return "translate-x-3"
        case "md": return "translate-x-6"
        case "lg": return "translate-x-11"
        default: return "translate-x-6"
    }
}



interface Props {
    checked: boolean,
    disabled?: boolean,
    label?: string,
    size?: "sm" | "md" | "lg",
    onChange: (value: boolean) => void
    field?: ControllerRenderProps<any, any>;
    id?: string;
    name?: string;
    color?: string; // this is to overwrite the bg-information when toggled-on, pass the entire tailwind classname (i.e. 'bg-warning')
    productFruitClass?: string;
}


function SwitchButton (props: Props) {
    const getFontSizeClassNames = (): string => {
        switch(props.size){
            case "sm": return 'text-[14px]'
            case "md": return 'text-[16px]'
            case "lg": return 'text-[18px]'
            default: return 'text=[14px]'
        }
    }

    return (
        <Switch.Group as="div" className="flex items-center p-1">
            <Switch
                disabled={!!props.disabled}
                checked={props.checked}
                onChange={props.onChange}
                className={classNames(
                    props.disabled ? "bg-disabled" : props.checked ? (props.color ? props.color : `bg-information`) : 'bg-neutralPrimary-300 focus:shadow-md focus:shadow-gray-20 focus:shadow-offset-2',
                    `relative inline-flex flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out ${getSwitchSize(props.size)}`,
                    ` ${props.productFruitClass}`
                )}
            >
                <input
                    id={props.id ? props.id : props.name}
                    type="hidden"
                    name={props.name}
                    value={props.checked ? "1" : "0"}
                    ref={props.field?.ref}
                />
                <span
                    aria-hidden="true"
                    className={classNames(
                        props.checked ? getTranslateValue(props.size) : 'translate-x-0',
                        `pointer-events-none inline-block transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out ${getInnerSwitchSize(props.size)}`
                    )}
                />
            </Switch>
            <Switch.Label as="span" className="ml-3">
                <span className={classNames('font-sm', props.disabled ? 'text-disabled-100' : 'text-neutralSecondary')}>{props.label}</span>
            </Switch.Label>
        </Switch.Group>
    )
}

export default SwitchButton;