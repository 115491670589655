import {ILoaderData} from "../interfaces/mainInterfaces";
import API from "./Api";
import LegalfileHelper from "./LegalfileHelper";
import {getEstatePlanSummary} from "./EstatePlanHelper";
import EG from "./EG";

export default function loader(portal: 'client' | 'admin' | 'partner' | 'login') {
    switch (portal) {
        case 'admin':
            return adminLoader;
        case 'partner':
            return partnerLoader;
        case "client":
            return clientLoader;
        case 'login':
            return loginLoader;
    }
}

async function clientLoader() {
    let result: ILoaderData = {
        user: null,
        legalFile: null,
        estatePlanSummary: null
    }

    await API.getDevData();

    const response: any = await API.get('usercache');

    if (response?.status == 200) {
        const user = await response.json();
        result.legalFile = await LegalfileHelper.retrieveCompleteLegalFile(user?.estatePlanId, null, '');
        if (result.legalFile) {
            result.estatePlanSummary = await getEstatePlanSummary(user?.estatePlanId);
        }
        result.user = {
            ...user,
            isSysAdmin: EG.isSysAdmin(user.systemPrivs.roles),
            isMoreThanACustomer: EG.isMoreThanACustomer(user.systemPrivs.roles),
            isAdvisor: EG.isAdvisor(user.systemPrivs.roles),
            isSysCustomerService: EG.isSysCustomerService(user.systemPrivs.roles),
            isAccountManager: EG.isAccountManager(user.systemPrivs.roles),
            isCustService: EG.isCustService(user.systemPrivs.roles),
            isAttorney: EG.isAttorney(user.systemPrivs.roles),
            isLegalOps: EG.isLegalOps(user.systemPrivs.roles),
            isOwner: EG.isOwner(user.user.id, result.legalFile),
        }
    }

    return result;
}

async function loginLoader() {
    let result: ILoaderData = {user: null}

    await API.getDevData();

    const response: any = await API.get('usercache');

    if (response?.status == 200) {
        result.user = await response.json();
        return result;
    }
    else {
        return result;
    }
}

async function adminLoader() {
    let userCacheObject = null;
    const response: any = await API.get('usercache');
    if (response?.status == 200) {
        userCacheObject = await response.json();
    }

    await API.getDevData();
//     TODO: Get other data that we need for this portal

    // window.server_data.egPartnerClientFile  //"NoPartner.js"
    return {user: userCacheObject};
}

async function partnerLoader() {
    let result: ILoaderData = {user: null};
    const response: any = await API.get('usercache');

    if (response?.status == 200) {
        const user = await response.json();
        result.user = {
            ...user,
            isSysAdmin: EG.isSysAdmin(user.systemPrivs.roles),
            isMoreThanACustomer: EG.isMoreThanACustomer(user.systemPrivs.roles),
            isAdvisor: EG.isAdvisor(user.systemPrivs.roles),
            isSysCustomerService: EG.isSysCustomerService(user.systemPrivs.roles),
            isAccountManager: EG.isAccountManager(user.systemPrivs.roles),
            isCustService: EG.isCustService(user.systemPrivs.roles),
            isAttorney: EG.isAttorney(user.systemPrivs.roles),
            isLegalOps: EG.isLegalOps(user.systemPrivs.roles),
            isAdmin: EG.isAdmin(user.systemPrivs.roles),
        }
    }

    await API.getDevData();
//     TODO: Get other data that we need for this portal

    // window.server_data.egPartnerClientFile  //"NoPartner.js"
    return result;
}